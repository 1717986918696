import { Component, OnChanges, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import { IUser } from '../../models/user.model';
import { DebugFormComponent } from '../../debug/debug-form/debug-form.component';
import { _ } from 'core-js';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { environment } from '../../../environments/environment';

function passwordMatcher(c: AbstractControl): any {
    return c.get('pw1').value === c.get('pw2').value ?
        undefined : { nomatch: true };
}

@Component({
    selector: 'gk-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnChanges, AfterViewInit {

    @Input()
    user: IUser;

    @ViewChild(DebugFormComponent)
    debugView: DebugFormComponent;

    showDebugInfo: boolean = !environment.production;

    action: String = 'modify';

    public userForm: FormGroup;

    constructor(
        private fb: FormBuilder,
    ) {
        this.createForm();
    }

    ngOnChanges(): void {
        this.rebuildForm();
    }

    ngAfterViewInit(): void {
        // wait a tick first to avoid one-time devMode unidirectional-data-flow-violation error
        // see https://angular.io/guide/component-interaction#parent-calls-an-viewchild
        if (!this.showDebugInfo) {
            setTimeout(() => this.debugView.formControl = this.userForm);
        }
    }

    onActivateChanged($event: any): void {
        this.enableRoles();
    }

    public restore(): void {
        this.rebuildForm();
    }

    public exportUser(): IUser {
        if (!this.userForm.valid) {
            return undefined;
        } else {
            let value: any = this.userForm.value;
            let user: IUser = <IUser>{
                email: value.email,
                name: value.name,
                isActive: value.active,
                hasSignatoryRole: false,
                hasAuditorRole: value.roles.auditor,
                hasAdminRole: value.roles.admin,
                hasPublisherRole: false,
                hasSubaccountRole: value.roles.subaccount,
            };
            // If there's a password, then its a create operation
            user.password = (!!value.password && !!value.password.pw1) ? value.password.pw1 : undefined;

            // If there's a user.id, then it's an edit operation
            user.id = (!!this.user.id) ? this.user.id : undefined;

            return user;
        }
    }

    private createForm(): void {
        this.userForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
            email: ['', [Validators.required, Validators.maxLength(30), Validators.email]],
            password: this.fb.group({
                pw1: [{
                    value: '',
                    disabled: true,
                }, [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
                pw2: [{
                    value: '',
                    disabled: true,
                }, [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
            }, { validator: passwordMatcher }),
            active: false,
            roles: this.fb.group({
                admin: false,
                subaccount: false,
                auditor: false,
            }),
        });
    }

    private rebuildForm(): void {
        let passwordDefault: string = '';
        if (!!this.user && !this.user.id) {
            this.userForm.get('password').enable();
        } else {
            this.userForm.get('password').disable();
            passwordDefault = 'xxxxxxxxxxxxxxxx'; // filler to make it look good.
        }
        if (!!this.user) {
            this.userForm.reset({
                name: this.user.name,
                email: this.user.email,
                active: this.user.isActive,
                password: {
                    pw1: passwordDefault,
                    pw2: passwordDefault,
                },
                roles: {
                    admin: this.user.hasAdminRole,
                    subaccount: this.user.hasSubaccountRole,
                    auditor: this.user.hasAuditorRole,
                },
            });
        }
        this.enableRoles();
    }

    private enableRoles(): void {
        let isEnabled: boolean = this.userForm.get('active').value;
        if (isEnabled) {
            this.userForm.get('roles').enable();
        } else {
            this.userForm.get('roles').disable();
        }
    }

}
