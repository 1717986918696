import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

// import { VersionInfo } from '../../version_info';

@Component({
    selector: 'gk-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent {

    version: string = "VersionInfo.version";
    build: string = "VersionInfo.build";
    date: string = "VersionInfo.date";

    constructor(public dialogRef: MatDialogRef<AboutDialogComponent>) {

    }
}
