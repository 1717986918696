import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';
import { IScheduledContent } from '../models/scheduled-content.model';


@Injectable()
export class ScheduledContentService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    get params(): HttpParams {
        return new HttpParams().set('expand', 'location');
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IScheduledContent>> {
        let url: string = environment.apiEndpoint + '/scheduledContent';
        return this.http.get<IDataArray<IScheduledContent>>(url, { headers: this.headers, params: this.params })
            .pipe(
                map((retval: IDataArray<IScheduledContent>) => {
                    retval.data = retval.data.map((item: IScheduledContent) => this.decodeScheduledContent(item));
                    return retval;
                }),
        );
    }

    getById(id: string): Observable<IScheduledContent> {
        let url: string = environment.apiEndpoint + '/scheduledContent/' + id;
        return this.http.get<IScheduledContent>(url, { headers: this.headers })
            .pipe(
                map((retval: IScheduledContent) => {
                    return this.decodeScheduledContent(retval);
                }),
        );
    }

    create(scheduledContent: IScheduledContent): Observable<IRemoteReference<IScheduledContent>> {
        let url: string = environment.apiEndpoint + '/scheduledContent';
        // return this.http.post<IRemoteReference<IScheduledContent>>(url, scheduledContent, { headers: this.headers, observe: 'response' });
        const encodedItem: IScheduledContent = this.encodeScheduledContent(scheduledContent);
        return this.http.post<IRemoteReference<IScheduledContent>>(url, encodedItem, { headers: this.headers });
    }

    update(scheduledContent: IScheduledContent): Observable<void> {
        let url: string = environment.apiEndpoint + '/scheduledContent/' + scheduledContent.id;
        const encodedItem: IScheduledContent = this.encodeScheduledContent(scheduledContent);
        return this.http.put<void>(url, encodedItem, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/scheduledContent/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }

    encodeScheduledContent(input: IScheduledContent): IScheduledContent {
        let clone: IScheduledContent = Object.assign({}, input);
        clone.recurranceRule = this.encodeRRule(input.recurranceRule);
        return clone;
    }

    decodeScheduledContent(input: IScheduledContent): IScheduledContent {
        let clone: IScheduledContent = Object.assign({}, input);
        clone.recurranceRule = this.decodeRRule(input.recurranceRule);
        return clone;
    }

    // RRule will natively show local time but save in UTC (doing time/date math as needed)
    // e.g. Tell rrule an event starts at 3am local in LAX (-08:00),
    // it will store the time as 11am UTC.
    // This is a problem because we'd really like to store the local time as timezone-free.
    encodeRRule(input: string): string {
        const modified: string = input.replace(/(\d{8}T\d{6})Z/g, (whole: string, datetimeOnly: string): string => {
            const adjusted: string = moment(whole).local().format('YYYYMMDD[T]HHmmSS[Z]');
            return adjusted;
        });
        return modified;
    }

    decodeRRule(input: string): string {
        const modified: string = input.replace(/(\d{8}T\d{6})Z/g, (whole: string, datetimeOnly: string): string => {
            const adjusted: string = moment(datetimeOnly).utc().format('YYYYMMDD[T]HHmmSS[Z]');
            return adjusted;
        });
        return modified;
    }
}


