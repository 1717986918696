import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NGXLogger } from 'ngx-logger';

import { IImage } from '../../models/image.model';
import { IVideo } from '../../models/video.model';
import { IPlaylistItem } from '../../models/playlist.model';
import { ImageService, VideoService, UrlService } from '../../services';
import { IUrl } from '../../models/url.model';

@Component({
    selector: 'gk-playlistitem-editor',
    templateUrl: './playlistitem.editor.component.html',
    styleUrls: ['./playlistitem.editor.component.scss'],
})
export class PlaylistItemEditorComponent implements OnInit {

    @Input() playlistItem: any; // (why does specifying cause a warning)
    @Output() onItemModified: EventEmitter<PlaylistItemEditorComponent> = new EventEmitter<PlaylistItemEditorComponent>();
    @ViewChild('itemForm') itemForm: NgForm;

    public video: IVideo;

    public image: IImage;

    public url: IUrl;

    constructor(
        private _image: ImageService,
        private _video: VideoService,
        private _url: UrlService,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        this.refreshData();
    }

    /**
     * True if the timing is changed.
     */
    get dirty(): boolean {
        return this.itemForm.dirty;
    }

    /*
    ngOnChanges(changes: SimpleChanges) {
        if (changes['playlistItem']) {
            if (changes['playlistItem'].currentValue) {
                this.refreshData();
            }
            if (changes['playlistItem'].previousValue) {
                this.onItemModified.emit(this);
            }
        }
    }
    */

    private refreshData(): void {
        if (!this.playlistItem) {
            this.logger.error('what is this?');
        } else if (this.playlistItem.itemType === 'video') {
            this.refreshVideoData();
        } else if (this.playlistItem.itemType === 'image') {
            this.refreshImageData();
        } else if (this.playlistItem.itemType === 'url') {
            this.refreshUrlData();
        }
    }

    private refreshImageData(): void {
        this._image.getById(this.playlistItem.itemId).subscribe(
            (result: IImage) => this.image = result,
            (err: any) => this.logger.error('refreshImageData()', err),
        );
        // this.image = this.playlistItem
        // console.log(this.playlistItem)
            // {
            //     "id": "image_13465d0c-1be7-4f6a-86a5-15433ecbd91a",
            //     "title": "Autumn Road",
            //     "description": "some long description",
            //     "thumbnailUrl": "https://cdn.pixabay.com/photo/2014/01/18/10/14/vaulted-cellar-247391__340.jpg",
            //     "imageUrl": "https://cdn.pixabay.com/photo/2014/01/18/10/14/vaulted-cellar-247391__340.jpg",
            //     "mimeType": "image/jpeg",
            //     "width": 958,
            //     "height": 638,
            //     "fileSizeBytes": 316625
            //   },
            //   {
            //     "id": "image_13465d0c-1be7-4f6a-86a5-15433ecbd91abc",
            //     "title": "pic2",
            //     "description": "some long description",
            //     "thumbnailUrl": "https://cdn.pixabay.com/photo/2017/01/19/23/46/church-1993645__340.jpg",
            //     "imageUrl": "https://cdn.pixabay.com/photo/2017/01/19/23/46/church-1993645__340.jpg",
            //     "mimeType": "image/jpeg",
            //     "width": 958,
            //     "height": 638,
            //     "fileSizeBytes": 316625
            //   }
    }

    private refreshVideoData(): void {
        this._video.getById(this.playlistItem.itemId).subscribe(
            (result: IVideo) => this.video = result,
            (err: any) => this.logger.error('refreshVideoData', err),
        );
    }

    private refreshUrlData(): void {
        this._url.getById(this.playlistItem.itemId).subscribe(
            (result: IUrl) => this.url = result,
            (err: any) => this.logger.error('refreshUrlData', err),
        );
    }
}
