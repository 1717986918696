
import { TdLoadingService } from '@covalent/core';
import { Router } from '@angular/router';

import { MatDialog, MatSnackBar } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { BaseScreen } from './base-screen';
/**
 * base class for editors in general
 */
export class BaseEditor extends BaseScreen {

    // protected isSaving: boolean = false; // set to true during the save process

    /**
     * true iff the editor has changed anything that needs saving
     */
    get dirty(): boolean { return false; }

    /**
     * true iff the editor is in a valid state
     */
    get valid(): boolean { return false; }

    constructor(
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        super(router, dialog, snackBar, loadingService, logger);
    }

}
