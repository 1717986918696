import { Component, OnInit, Input } from '@angular/core';

import { ILocation } from '../models/location.model';

@Component({
    selector: 'gk-location-mapview',
    templateUrl: './location.mapview.component.html',
    styleUrls: ['./location.mapview.component.scss'],
})
export class LocationMapViewComponent {

    @Input() location: ILocation;

}
