import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gk-deus-modus-screen',
    templateUrl: './deus-modus-screen.component.html',
    styleUrls: ['./deus-modus-screen.component.scss'],
})
export class DeusModusScreenComponent implements OnInit {

    constructor() {
        // todo
    }

    ngOnInit(): void {
        // todo
    }

    onAccountChangedEvent($event: any): void {
        // todo
    }
}
