import { Injectable, Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { Observable } from 'rxjs';

import { TdMediaService, TdDialogService, TdLoadingService, LoadingType, LoadingMode } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { IUser } from '../../models/user.model';
import { UserService } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { PasswordDialogService } from '../../dialogs/password-dialog.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';
import { environment } from '../../environment';

@Injectable()
export class CanDeactivateUserForm implements CanDeactivate<UserFormComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: UserFormComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-user-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    viewProviders: [UserService],
    providers: [L10nService],
})
export class UserFormComponent implements OnInit, AfterViewInit {

    private _isSaving: boolean = false; // used to short-circuit leave-without-save
    private readonly _spinnerName: string = 'saving';

    @ViewChild('myForm') myForm: NgForm;

    name: string;
    email: string;
    id: string;
    isAdmin: boolean;
    isActive: boolean;
    hasSignatoryRole?: boolean;
    hasAuditorRole?: boolean;
    hasAdminRole?: boolean;
    hasPublisherRole?: boolean;
    hasSubaccountRole?: boolean;

    public usertest: IUser;
    user: any;
    action: string;
    cardTitle: string;
    password: string;
    repassword: string;

    constructor(private _users: UserService,
        private _route: ActivatedRoute,
        public media: TdMediaService,
        private _dialogService: TdDialogService,
        private _viewContainerRef: ViewContainerRef,
        private _passwordService: PasswordDialogService,
        private snackBarService: MatSnackBar,
        private loadingService: TdLoadingService,
        private logger: NGXLogger,
    ) {
        this.loadingService.create({
            name: this._spinnerName,
            type: LoadingType.Circular,
            mode: LoadingMode.Indeterminate,
            color: 'accent',
        });

        this.usertest = <IUser>{
            email: 'test@test.com',
            name: 'Test Id',
            isActive: true,
            isAdmin: false,
            hasSignatoryRole: false,
            hasAuditorRole: false,
            hasAdminRole: false,
            hasPublisherRole: false,
            hasSubaccountRole: false,
        };
    }

    goBack(): void {
        this.loadingService.resolve(this._spinnerName);
        window.history.back();
    }

    restore(): void {
        // console.log('foo');
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    ngOnInit(): void {
        this._route.url.subscribe((url: any) => {
            this.action = (url.length > 1 ? url[1].path : 'add');
            if (this.action === 'add') {
                this.isActive = true;
                this.cardTitle = 'UserEditor.CardTitle.Create';
            } else {
                this.loadData();
                this.cardTitle = 'UserEditor.CardTitle.Modify';
            }
        });
    }

    get dirty(): boolean {
        return this.myForm.touched && this.myForm.dirty && !this._isSaving;
    }

    get showDebug(): boolean {
        return !environment.production;
    }

    save(): void {
        this._isSaving = true;
        this.loadingService.register(this._spinnerName);
        let now: Date = new Date();
        this.user = {
            name: this.name,
            email: this.email,
            // isAdmin: this.isAdmin,
            isActive: this.isActive,
            id: this.id,
            hasAdminRole: this.hasAdminRole,
            hasSignatoryRole: this.hasSignatoryRole,
            hasAuditorRole: this.hasAuditorRole,
            hasPublisherRole: this.hasPublisherRole,
            hasSubaccountRole: this.hasSubaccountRole,
        };
        if (this.action === 'add') {
            delete this.user.id;
            this.user.password = this.password;
            this._users.create(this.user).subscribe(() => {
                this.goBack();
            });
        } else {
            this._users.update(this.user).subscribe(() => {
                this.goBack();
            });
        }
    }

    changePassword(): void {
        this._passwordService.confirm('confirm dialog', 'are you sure?')
            .subscribe((newPassword: string) => {
                this._users.changePassword(this.id, newPassword).subscribe(
                    () => {
                        this.snackBarService.open('Password changed.', 'Ok', { duration: 3000 });
                    },
                    (err: any) => {
                        this.snackBarService.open('Error: Change password failed.', 'Ok', { duration: 3000 });
                    },
                );
            });
    }

    private loadData(): void {
        this._route.params.subscribe(
            (params: { id: string }) => {
                let userId: string = params.id;
                this._users.getById(userId).subscribe(
                    (user: any) => {
                        // this.user = user;
                        this.name = user.name;
                        this.email = user.email;
                        this.isAdmin = (user.siteAdmin === 1 ? true : false);
                        this.isActive = user.isActive;
                        this.id = user.id;
                        this.hasAdminRole = user.hasAdminRole;
                        this.hasSignatoryRole = user.hasSignatoryRole;
                        this.hasAuditorRole = user.hasAuditorRole;
                        this.hasPublisherRole = user.hasPublisherRole;
                        this.hasSubaccountRole = user.hasSubaccountRole;
                    },
                    (err: any) => {
                        this.logger.log('Error: UserForm.loadData: user ID not found.');
                        this.goBack();
                    },
                );
            },
            (err: any) => {
                this.logger.log('Error: UserForm.loadData: invalid routing params.');
                this.goBack();
            },
        );
    }
}
