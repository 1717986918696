import { Component, OnChanges, SimpleChanges, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { AccountTreeNode } from '../user-menu.component';

export class AccountFlatNode {
    constructor(
        public expandable: boolean, public label: string, public level: number, public id: string) { }
}

@Component({
    selector: 'gk-account-tree',
    templateUrl: './account-tree.component.html',
    styleUrls: ['./account-tree.component.scss'],
})
export class AccountTreeComponent implements OnChanges {

    @Input()
    accounts: [AccountTreeNode];

    @Output()
    choseAccount: EventEmitter<string> = new EventEmitter<string>();

    treeControl: FlatTreeControl<AccountFlatNode>;
    treeFlattener: MatTreeFlattener<AccountTreeNode, AccountFlatNode>;
    dataSource: MatTreeFlatDataSource<AccountTreeNode, AccountFlatNode>;

    constructor() {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<AccountFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const accounts: SimpleChange = changes.accounts;
        if (!!accounts.currentValue) {
            this.dataSource.data = accounts.currentValue;
        }
    }

    onChoseAccount(id: string): void {
        this.choseAccount.emit(id);
    }

    hasChild = (_: number, nodeData: AccountFlatNode) => nodeData.expandable;

    private transformer = (node: AccountTreeNode, level: number) => {
        return new AccountFlatNode(node.children.length > 0, node.data.name, level, node.data.id);
    }
    private getLevel = (node: AccountFlatNode) => node.level;

    private isExpandable = (node: AccountFlatNode) => node.expandable;

    private getChildren = (node: AccountTreeNode): AccountTreeNode[] => node.children;

}
