import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AudienceService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    get(screenId: string, dateTime: string): Observable<string> {
        let url: string = environment.apiEndpoint +
            '/audience/datetime/' + dateTime + '/at/' + screenId;
        return this.http.get<string>(url, { headers: this.headers });

    }
}
