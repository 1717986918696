import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, interval, from } from 'rxjs';
import { take, switchMap, find, map, skip, catchError, first } from 'rxjs/operators';

/* from, interval, tap, take, switchMap, find, skip, first, catchError, mapTo*/

import { IHandshake } from '../models/handshake.model';
import { IDataArray, IRemoteReference } from '../models/data-array.model';

import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HandshakeService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        // headers = this.auth.addAuthHttpHeaders(headers);
        // headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getItem(hardwareId: string): Observable<IHandshake> {
        let url: string = environment.handshakeEndpoint + '/handshakes/' + hardwareId;
        return this.http.get<IHandshake>(url, { headers: this.headers });
    }

    /*
    getByScreenId(screenId: string): Observable<IHandshake> {
        let url: string = environment.apiEndpoint + '/handshakes/screen/' + screenId;
        return this.http.get<IHandshake>(url, { headers: this.headers });
    }
    */

    createItem(handshake: IHandshake): Observable<IRemoteReference<IHandshake>> {
        let url: string = environment.handshakeEndpoint + '/handshakes';
        return this.http.post<IRemoteReference<IHandshake>>(url, handshake, { headers: this.headers });
    }

    deleteItem(hardwareId: string): Observable<void> {
        let url: string = environment.handshakeEndpoint + `/handshakes/${hardwareId}`;
        return this.http.delete<void>(url, { headers: this.headers });
    }

    pollForHandshake(hardwareId: string, nRetries: number, intervalMSecs: number): Observable<boolean> {
        return interval(intervalMSecs)                                  // repeat every m msecs
            .pipe(
                take(nRetries),                                         // up to n tries
                switchMap(() => this.getItem(hardwareId)),              // get the handshake by hardwareId
                find((value: IHandshake) => value.token === 'matched'), // find one that is matched (emits undefined if none found)
                map((value: IHandshake) => !!value),                    // return true if matched, false otherwise
        );
    }

    pollForDeleted(hardwareId: string, nRetries: number, intervalMSecs: number): Observable<boolean> {
        return interval(intervalMSecs) // repeat every m msecs
            .pipe(
                switchMap(() => this.getItem(hardwareId)),        // get the handshake by hardwareId
                map((value: IHandshake) => {
                    if (!value.screenId || value.screenId === '') {
                        throw new Error('not found');
                    }
                    return false;
                }),                                               // convert found instances to a boolean result
                skip(nRetries),                                   // ignore [nRetries] the found instances
                catchError((err: any) => from([true])),           // catch errors and convert them to a positive result
                first(),                                          // return first result that gets so far.
        );
        /*
        .find((value: IHandshake) => value.token === 'matched') // find one that is matched (emits undefined if none found)
        .catch((err: any, caught: Observable<IHandshake>) => caught)
*/

    }

    /*
    deleteByScreenId(screenId: string): Observable<void> {
        let url: string = environment.apiEndpoint + `/handshakes/hardware/${screenId}`;
        return this.http.delete<void>(url, { headers: this.headers });
    }
    */
}
