import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { TdLoadingService } from '@covalent/core';
import { TdDialogService } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService } from '@ngx-translate/core';

import { L10nService } from '../l10n/l10n.service';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'gk-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [L10nService],
})
export class LoginComponent {

    email: string;
    password: string;
    rememberMe: boolean;

    constructor(private router: Router,
        private auth: AuthService,
        private _loadingService: TdLoadingService,
        private _dialogService: TdDialogService,
        private _viewContiainerRef: ViewContainerRef,
        private translate: TranslateService,
        private logger: NGXLogger,
    ) { }

    login(): void {
        this._loadingService.register();
        this.logger.log('login', this.email);
        this.auth.login({ 'email': this.email, 'password': this.password }, this.rememberMe).subscribe(
            (res: boolean) => {
                if (res) {
                    this.router.navigate(['/dashboard']);
                } else {
                    this.logger.log('Invalid user');
                }
            },
            (err: any) => {
                this.logger.error('authentication failed', err);
                this._dialogService.openAlert({
                    title: 'Login Failed',
                    message: 'Cannot connect to service to validate.',
                    closeButton: 'Close', // OPTIONAL, defaults to 'CLOSE'
                })
                    .afterClosed().subscribe(() => {
                        this.password = '';
                        this._loadingService.resolve();
                    });
            },
            () => {
                this._loadingService.resolve();
            },
        );
    }

    reset(): void {
        const translationPhrases: string[] = [
            'Login.PasswordResetDialog.DialogTitle',
            'Login.PasswordResetDialog.DialogMessage',
            'Login.PasswordResetDialog.CancelButton',
            'Login.PasswordResetDialog.OkayButton',
        ];

        this.translate.get(translationPhrases).subscribe(
            (res: any) => this.reset_translated(res),
        );
    }

    reset_translated(phrases: string[]): void {
        this._dialogService.openPrompt({
            title: phrases['Login.PasswordResetDialog.DialogTitle'],
            message: phrases['Login.PasswordResetDialog.DialogMessage'],
            value: this.email,
            viewContainerRef: this._viewContiainerRef,
            cancelButton: phrases['Login.PasswordResetDialog.CancelButton'],
            acceptButton: phrases['Login.PasswordResetDialog.OkayButton'],
        }).afterClosed().subscribe((email: string) => {
            if (email) {
                this.alertPasswordSent();
            } else {
                this.alertPasswordFailed();
            }
        });
    }

    alertPasswordSent(): void {
        const translatePhrases: string[] = [
            'Login.PasswordSentDialog.DialogTitle',
            'Login.PasswordSentDialog.DialogMessage',
            'Login.PasswordSentDialog.CloseButton',
        ];
        this.translate.get(translatePhrases).subscribe(
            (res: any) => {
                this._dialogService.openAlert({
                    title: res['Login.PasswordSentDialog.DialogTitle'], // 'New Password Sent',
                    message: res['Login.PasswordSentDialog.DialogMessage'], // 'Check your email for temporary password.',
                    closeButton: res['Login.PasswordSentDialog.CloseButton'], // 'Close',
                    viewContainerRef: this._viewContiainerRef,
                });
            },
        );
    }

    alertPasswordFailed(): void {
        const translatePhrases: string[] = [
            'Login.PasswordNotSentDialog.DialogTitle',
            'Login.PasswordNotSentDialog.DialogMessage',
            'Login.PasswordNotSentDialog.CloseButton',
        ];
        this.translate.get(translatePhrases).subscribe(
            (res: any) => {
                this._dialogService.openAlert({
                    title: res['Login.PasswordNotSentDialog.DialogTitle'],
                    message: res['Login.PasswordNotSentDialog.DialogMessage'],
                    closeButton: res['Login.PasswordNotSentDialog.CloseButton'],
                    viewContainerRef: this._viewContiainerRef,
                });
            },
        );
    }
}
