import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class L10nService {

    private static oneTimeInit: boolean = false;

    constructor(
        private translate: TranslateService,
        private logger: NGXLogger,
    ) {
        if (!L10nService.oneTimeInit) {
            L10nService.oneTimeInit = true;

            translate.addLangs(['en', 'es', 'jp', 'zh-Hans']);
            translate.setDefaultLang('en');

            let browserLang: string = translate.getBrowserLang();
            this.logger.log('browser lang = ', browserLang);
            translate.use(browserLang.match(/en|es|jp|zh\-Hans/) ? browserLang : 'en');
        }
    }

    public getLanguage(): string {
        return this.translate.currentLang;
    }

    public changeLanguage(lang: string): void {
        this.logger.log('Change language to', lang);
        this.translate.use(lang);
    }
}
