import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { MediaComponent } from './media/media.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { PlaylistFormComponent, CanDeactivatePlaylistForm } from './playlists/+form/playlist.form.component';
import { MediaVideoEditorComponent, CanDeactivateMediaVideoEditor } from './media/editors/media-video-editor.component';
import { HashtagFormComponent } from './media/+form/hashtag.form.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScreensComponent } from './screens/screens.component';
import { ScreenEditorComponent, CanDeactivateScreenEditor } from './screens/editors/screen.editor.component';
import { ChannelEditorComponent, CanDeactivateChannelEditor } from './screens/editors/channel.editor.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountFormComponent, CanDeactivateAccountForm } from './accounts/+form/form.component';
import { UsersComponent } from './users/users.component';
import { UserEditorComponent, CanDeactivateUserEditor } from './users/user-editor/user-editor.component';
import { FinanceComponent } from './finance/finance.component';
import { MediaImageEditorComponent, CanDeactivateMediaImageEditor } from './media/editors/media-image-editor.component';
import { MediaUrlEditorComponent, CanDeactivateMediaUrlEditor } from './media/editors/media-url-editor.component';
import { DeusModusComponent } from './deus-modus/deus-modus.component';
import { DeusModusScreenComponent } from './deus-modus/screen/deus-modus-screen.component';
import { ScreenAudienceComponent } from './screens/screen-audience.component';
import { AdCampaignsComponent } from './ad-campaigns/ad-campaigns.component';
import { SysOpsComponent } from './sys-ops/sys-ops.component';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { UrlComponent } from './URL/url.component';

const routes: Routes = [

    { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
    // fake
    {
        path: '', component: MainComponent, children: [
            { path: '', component: DashboardComponent, canActivate: [AuthGuard] },

            {
                path: 'accounts', children: [
                    { path: '', component: AccountsComponent, canActivate: [AuthGuard] },
                    { path: 'add', component: AccountFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateAccountForm] },
                    { path: ':id/delete', component: AccountFormComponent, canActivate: [AuthGuard] },
                    { path: ':id/edit', component: AccountFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateAccountForm] },
                ],
            },

            {
                path: 'users', children: [
                    { path: '', component: UsersComponent, canActivate: [AuthGuard] },
                    { path: 'add', component: UserEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateUserEditor] },
                    { path: ':id/edit', component: UserEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateUserEditor] },
                ],
            },
            {
                path: 'deusModus', children: [
                    { path: '', component: DeusModusComponent, canActivate: [AuthGuard] },
                    { path: 'screen/:id', component: DeusModusScreenComponent, canActivate: [AuthGuard] },
                ],
            },
            {
                path: 'media', children: [
                    { path: '', component: MediaComponent, canActivate: [AuthGuard] },
                    {
                        path: 'images/add', component: MediaImageEditorComponent, canActivate: [AuthGuard],
                        canDeactivate: [CanDeactivateMediaImageEditor],
                    },
                    { path: 'images/:id/delete', component: MediaImageEditorComponent, canActivate: [AuthGuard] },
                    {
                        path: 'images/:id/edit', component: MediaImageEditorComponent,
                        canActivate: [AuthGuard], canDeactivate: [CanDeactivateMediaImageEditor],
                    },
                    {
                        path: 'videos/add', component: MediaVideoEditorComponent,
                        canActivate: [AuthGuard], canDeactivate: [CanDeactivateMediaVideoEditor],
                    },
                    { path: 'videos/:id/delete', component: MediaVideoEditorComponent, canActivate: [AuthGuard] },
                    {
                        path: 'videos/:id/edit', component: MediaVideoEditorComponent,
                        canActivate: [AuthGuard], canDeactivate: [CanDeactivateMediaVideoEditor],
                    },
                    {
                        path: 'urls/add', component: MediaUrlEditorComponent, canActivate: [AuthGuard],
                        canDeactivate: [CanDeactivateMediaUrlEditor],
                    },
                    { path: 'urls/:id/delete', component: MediaUrlEditorComponent, canActivate: [AuthGuard] },
                    {
                        path: 'urls/:id/edit', component: MediaUrlEditorComponent,
                        canActivate: [AuthGuard], canDeactivate: [CanDeactivateMediaUrlEditor],
                    },
                    { path: 'hashtags/add', component: HashtagFormComponent, canActivate: [AuthGuard] },
                    { path: 'hashtags/:id/delete', component: HashtagFormComponent, canActivate: [AuthGuard] },
                    { path: 'hashtags/:id/edit', component: HashtagFormComponent, canActivate: [AuthGuard] },
                ],
            },

            {
                path: 'playlists', children: [
                    { path: '', component: PlaylistsComponent, canActivate: [AuthGuard] },
                    { path: 'add', component: PlaylistFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivatePlaylistForm] },
                    { path: ':id/delete', component: PlaylistFormComponent, canActivate: [AuthGuard] },
                    { path: ':id/edit', component: PlaylistFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivatePlaylistForm] },
                ],
            },

            {
                path: 'screens', children: [
                    { path: '', component: ScreensComponent, canActivate: [AuthGuard] },
                    { path: 'add', component: ScreenEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateScreenEditor] },
                    { path: ':id/delete', component: ScreenEditorComponent, canActivate: [AuthGuard] },
                    { path: ':id/edit', component: ScreenEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateScreenEditor] },
                    { path: ':id/audience', component: ScreenAudienceComponent, canActivate: [AuthGuard] },
                    {
                        path: 'channels', children: [
                            { path: '', redirectTo: 'screens?tab=1', pathMatch: 'full' },
                            { path: 'add', component: ChannelEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateChannelEditor] },
                            { path: ':id/delete', component: ChannelEditorComponent, canActivate: [AuthGuard] },
                            {
                                path: ':id/edit', component: ChannelEditorComponent, canActivate: [AuthGuard],
                                canDeactivate: [CanDeactivateChannelEditor],
                            },
                        ],
                    },
                ],
            },
            { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard] },
            { path: 'layouts', component: LayoutsComponent, canActivate: [AuthGuard] },
            { path: 'finance', component: FinanceComponent, canActivate: [AuthGuard] },
            { path: 'campaigns', component: AdCampaignsComponent, canActivate: [AuthGuard] },
            { path: 'sysops', component: SysOpsComponent, canActivate: [AuthGuard] },
        ],
    },

    { path: 'dashboard', redirectTo: '', pathMatch: 'full' },
    { path: 'fakeUrl/:key', component: UrlComponent,canActivate: [AuthGuard]},

    { path: '**', component: LoginComponent },

];

export const appRoutingProviders: any[] = [

];

export const appRoutes: any = RouterModule.forRoot(routes, { useHash: true });
