import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'form-hashtags',
    templateUrl: './hashtag.form.component.html',
    styleUrls: ['./hashtag.form.component.scss']
})
export class HashtagFormComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
