import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { AudienceService } from '../services';

@Component({
    selector: 'gk-screen-audience',
    templateUrl: './screen-audience.component.html',
    styleUrls: ['./screen-audience.component.scss'],
})
export class ScreenAudienceComponent implements OnInit {

    screenId: string;
    dateTime: string;

    data: String = 'test';

    constructor(
        private audienceService: AudienceService,
        protected route: ActivatedRoute,
        private translate: TranslateService,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        this.screenId = this.route.snapshot.params['id'];
    }

    onAccountChangeEvent(event: any): void {
        //
        return;
    }

    search(): void {
        this.audienceService.get(this.screenId, this.dateTime)
            .subscribe(
                (newData: String) => {
                    try {
                        this.data = JSON.stringify(newData, undefined, 2);
                    } catch (e) {
                        this.data = newData;
                    }
                },
                (err: any) => {
                    try {
                        this.data = JSON.stringify(err, undefined, 2);
                    } catch (e) {
                        this.data = err;
                    }
                },
            );
    }
}
