import { CalendarDateFormatterInterface, DateFormatterParams } from 'angular-calendar';
import * as moment from 'moment';

export class CustomCalMomentDateFormatter implements CalendarDateFormatterInterface {

    monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('dddd');
    }

    monthViewDayNumber({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('D');
    }

    monthViewTitle({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('MMMM YYYY');
    }

    weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('ddd');
    }

    weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('D MMM');
    }

    weekViewTitle({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('[Woche] W [/] YYYY');
    }

    weekViewHour({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('[Woche] W [/] YYYY');
    }

    dayViewHour({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('HH:mm');
    }

    dayViewTitle({ date, locale }: DateFormatterParams): string {
        return moment(date)
            .locale(locale)
            .format('dd, DD.MM.YYYY');
    }
}
