import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IChannel } from '../models/channel.model';
import { IDataArray, IRemoteReference } from '../models/data-array.model';

import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ChannelService {

    get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IChannel>> {
        let url: string = environment.apiEndpoint + '/channels?expand[]=defaultPlaylist';
        return this.http.get<IDataArray<IChannel>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<IChannel> {
        let url: string = environment.apiEndpoint + '/channels/' + id;
        return this.http.get<IChannel>(url, { headers: this.headers });
    }

    create(channel: IChannel): Observable<IRemoteReference<IChannel>> {
        let url: string = environment.apiEndpoint + '/channels';
        return this.http.post<IRemoteReference<IChannel>>(url, channel, { headers: this.headers });
    }

    update(channel: IChannel): Observable<void> {
        let url: string = environment.apiEndpoint + `/channels/${channel.id}`;
        return this.http.put<void>(url, channel, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + `/channels/${id}`;
        return this.http.delete<void>(url, { headers: this.headers });
    }
}
