import { FormControl, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

    static isInteger(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        return !n || Number.isInteger(n) ? undefined : { notAnInteger: { value: n } };
    }

    static isInEnum(options: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let value: any = (control as FormControl).value;
            let found: string = options.find((item: string) => item === value);
            return found ? undefined : { notInEnum: { value: value } };
        };
    }

    static isDateStr(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        return moment(n, 'YYYY-MM-DD', true).isValid() ? undefined : { notADate: { value: n } };
    }

    static isTimeStr(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        return moment(n, 'HH:mm:ss', true).isValid() ? undefined : { notATime: { value: n } };
    }

    static isDatetimeStr(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        if (!n || (typeof n === 'string' && n.length === 0)) { return undefined; } // required check is elswhere
        return moment(n, 'YYYY-MM-DD[T]HH:mm:ss', true).isValid() ? undefined : { notADatetime: { value: n } };
    }

    static isDateOrDatetimeStr(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        if (!n || (typeof n === 'string' && n.length === 0)) { return undefined; } // required check is elswhere
        if (moment(n, 'YYYY-MM-DD[T]HH:mm:ss', true).isValid()) { return undefined; }
        if (moment(n, 'HH:mm:ss', true).isValid()) { return undefined; }
        return { notADateOrDatetime: { value: n } };
    }

    static isDuration(control: AbstractControl): { [key: string]: any } {
        let n: any = (control as FormControl).value;
        return moment.isDuration(moment.duration(n)) ? undefined : { notADuration: { value: n } };
    }

    static weeklyRulesHasAtLeastOneDay(control: AbstractControl): { [key: string]: any } {
        let onSunday: boolean = control.get('onSunday').value;
        let onMonday: boolean = control.get('onMonday').value;
        let onTuesday: boolean = control.get('onTuesday').value;
        let onWednesday: boolean = control.get('onWednesday').value;
        let onThursday: boolean = control.get('onThursday').value;
        let onFriday: boolean = control.get('onFriday').value;
        let onSaturday: boolean = control.get('onSaturday').value;
        return (onSunday || onMonday || onTuesday || onWednesday || onThursday || onFriday || onSaturday)
            ? undefined : { notOneDay: { value: 0 } };
    }
}
