import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { L10nService } from '../l10n/l10n.service';
import { ScreenService, ChannelService } from '../services';
import { IScreen } from '../models/screen.model';
import { IChannel } from '../models/channel.model';
import { IDataArray } from '../models/data-array.model';
import { environment } from '../../environments/environment';
import { ProfileService, ProfileSingleton } from '../auth/profile.service';
import { PreviewDialogComponent } from '../dialogs/preview-dialog/preview-dialog.component';
import { PairingDialogComponent } from '../dialogs/pairing-dialog/pairing-dialog.component';
import { PairingService } from '../services/pairing.service';
import { AuthService } from '../auth/auth.service';
import { GenerateUrlDialogComponent } from '../dialogs/generateUrl-dialog/generateUrl-dialog.component';

export interface IScreenProxy {
    name: string;
    description: string;
    location: {
        name: string;
        description: string;
    };
    locationAddress: string;
    paired: boolean;
    channelId: string;
    ids: { id: string; hardwareId: string };
}

@Component({
    selector: 'gk-screen-list',
    templateUrl: './screen.list.component.html',
    styleUrls: ['./screen.list.component.scss'],
})
export class ScreenListComponent implements OnInit, OnDestroy {

    private subscription: any;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    hasDeusModus: boolean = false;
    hasAuditorRole: boolean = false;

    data: IScreen[] = [];

    columns: ITdDataTableColumn[] = [
        { name: 'name', label: 'Name', width: 150 },
        // { name: 'description', label: 'Description', width: 150 },
        { name: 'location', label: 'Location', width: 150 },
        { name: 'locationAddress', label: 'Address' },
        { name: 'paired', label: 'Paired', width: 50 },
        { name: 'channel', label: 'Channel' },
        { name: 'ids', label: '', width: 100 },
    ];

    lookup: Object = {
        'name': 'ScreenList.ColHeader.Name',
        // 'description': 'ScreenList.ColHeader.Description',
        'location': 'ScreenList.ColHeader.Location',
        'locationAddress': 'ScreenList.ColHeader.Address',
        'paired': 'Paired',
        'channel': 'ScreenList.ColHeader.Channel',
    };

    filteredData: IScreenProxy[] = [];
    filteredTotal: number = this.data.length;

    selectedRows: IScreen[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'name';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    selectedValue: string;

    channels: IChannel[];

    constructor(
        private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _screenService: ScreenService,
        private _snackBarService: MatSnackBar,
        private _channelService: ChannelService,
        private _profileService: ProfileService,
        private _translate: TranslateService,
        private pairingService: PairingService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private auth: AuthService,
    ) { }

    ngOnInit(): void {
        this.subscription = this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadScreens();
        this.loadChannels();

        this._profileService.getProfile().subscribe(
            (profile: ProfileSingleton) => {
                if (profile.hasDeusModus) {
                    this.hasDeusModus = true;
                }
                if (profile.hasAuditorRole) {
                    this.hasAuditorRole = true;
                }
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAccountChangeEvent(event: any): void {
        this.data = [];
        this.loadScreens();
        this.loadChannels();
    }

    onSortEvent(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    onSearchTermEvent(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    onPageChangeEvent(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    onChannelChangeEvent(event: any, screenProxy: IScreenProxy): void {

        let screen: IScreen = this.data.find((val: IScreen) => val.id === screenProxy.ids.id);
        if (!screen) {
            this.logger.error('ScreenProxy should always refer to a source IScreen');
            return;
        }

        // NOTE: channelId === 'undefined' won't erase previous value in db
        //       so set it explicitly to null.
        // tslint:disable-next-line no-null-keyword
        screen.channelId = (!!event.value) ? event.value : null;

        this._screenService.update(screen).subscribe(
            (res: void) => {
                this.logger.log(res);
                this._snackBarService.open('Channel changed.', 'Ok', { duration: 3000 });
            },
            (err: any) => {
                this.logger.error(err);
                this._dialogService.openAlert({ message: 'Encountered and error changing channels.' });
            },
        );
    }

    filter(): void {
        let newData: any[] = this.data.map((item: IScreen) => <IScreenProxy>{
            name: item.name,
            location: {
                name: item.location.name,
                description: item.description,
            },
            locationAddress: item.location.formattedAddress,
            paired: !!item.hardwareId,
            channelId: item.channelId,
            ids: {
                id: item.id,
                hardwareId: item.hardwareId,
            },
        });
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
    }

    loadScreens(): void {
        this._screenService.getAll().subscribe(
            (dataArray: IDataArray<IScreen>) => {
                this.data = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
                this._dialogService.openAlert({ message: 'Encountered and error loading screens.' });
            },
            () => {
                this.logger.log('loaded screens', this.data);
                this.filter();
            },
        );
    }

    reload(): void {
        this.channels = [];
        this.data = [];
        this.filter();
        this.loadChannels();
        this.loadScreens();
    }

    loadChannels(): void {
        this._channelService.getAll().subscribe(
            (dataArray: IDataArray<IChannel>) => {
                this.channels = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
                this._dialogService.openAlert({ message: 'Encountered and error loading channels.' });
            },
            () => {
                this.logger.log('loaded channels', this.channels);
            });
    }

    pairingClick(id: string): void {
        this.logger.info('pairing %s', id);
        let screen: IScreen = this.data.find((item: IScreen) => item.id === id);
        let dialogRef: MatDialogRef<PairingDialogComponent, string> = this.dialog.open(PairingDialogComponent, {
            width: '500px',
            disableClose: true,
            data: {
                screen: screen,
                pairingService: this.pairingService,
            },
        });

        dialogRef.afterClosed().subscribe((hardwareId: string) => {
            // undefined implies the device hasn't paired
            this.logger.log('The dialog was closed, hardwareId=', hardwareId);
            this.reload();
        });

    }

    generateUrl(id: string): void {
        this.logger.info('pairing %s', id);
        let screen: IScreen = this.data.find((item: IScreen) => item.id === id);
        let url: string = environment.screenUrl + '/#/broadcast/'  + id + '?' +
        't=' + this.auth.getToken()
            
        const effectiveAccount: string = ProfileService.profileSingleton().effectiveAccountID;
        if (effectiveAccount) {
            url += '&a=' + effectiveAccount;
            url+= '?i=preview:' + ProfileService.profileSingleton().userID;
        }

        let dialogRef: MatDialogRef<GenerateUrlDialogComponent, string> = this.dialog.open(GenerateUrlDialogComponent, {
            width: '500px',
            disableClose: true,
            data: {
                screen: screen,
                pairingService: this.pairingService,
                url:url,
                id:id
            },
        });

        dialogRef.afterClosed().subscribe((hardwareId: string) => {
            // undefined implies the device hasn't paired
            this.logger.log('The dialog was closed, hardwareId=', hardwareId);
            this.reload();
        });

    }


    unpairingClick(id: string): void {
        this.logger.info('unpairing %s', id);
        let screen: IScreen = this.data.find((item: IScreen) => item.id === id);
        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to unpair this screen' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this.pairingService.unpairDevice(screen.id, screen.hardwareId).subscribe(
                        () => {
                            this._snackBarService.open('Pairing removed', 'Ok', { duration: 3000 });
                            this.reload();
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    onDeusModusClick(id: string): void {
        this.logger.info('deusModus on %s', id);
    }

    onDeleteClick(id: string): void {

        this.logger.log(this.data);

        let item: IScreen = this.data.find((x: IScreen) => x.id === id);
        let screenName: string = item.name;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + screenName + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._screenService.delete(id).subscribe(
                        (data: void) => {
                            this.data = this.data.filter((screen: IScreen) => {
                                return screen.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((screenProxy: IScreenProxy) => {
                                return screenProxy.ids.id !== id;
                            });
                            this._snackBarService.open('Screen deleted', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    onPreviewClick(id: string): void {
        let url: string = environment.screenUrl + '/#/broadcast/'  + id + '?' +
        't=' + this.auth.getToken()
            
        const effectiveAccount: string = ProfileService.profileSingleton().effectiveAccountID;
        if (effectiveAccount) {
            url += '&a=' + effectiveAccount;
            url+= '?i=preview:' + ProfileService.profileSingleton().userID;
        }

        let dialogRef: MatDialogRef<PreviewDialogComponent> = this.dialog.open(PreviewDialogComponent, {
            data: { url: url },
        });
        dialogRef.afterClosed().subscribe();
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this._translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }
}
