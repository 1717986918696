import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'gk-operation-failed-dialog',
    templateUrl: './operation-failed-dialog.component.html',
    styleUrls: ['./operation-failed-dialog.component.scss'],
})

/**
 * data: {
 *   title?: {
 *      message: string,
 *      translate?: boolean
 *   }
 *   explanation?: {
 *     message: string,
 *     translate?: boolean
 *   }
 *   details?: { string }
 *   error?: any
 * }
 *
 */
export class OperationFailedDialogComponent {

    title: string = 'Dialog.OperationFailed.Title';
    translateTitle: boolean = true;

    explanation: string = 'Dialog.OperationFailed.Explanation';
    translateExplanation: boolean = true;

    details: string = '';
    showDetails: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<OperationFailedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (!!data) {
            if (!!data.title) {
                if (!!data.title.message) {
                    this.title = data.title.message;
                }
                if (!!data.title.explanation) {
                    this.translateTitle = data.title.translate;
                }
            }
            if (!!data.explanation) {
                if (!!data.explanation.message) {
                    this.explanation = data.explanation.message;
                }
                if (!!data.explanation.translate) {
                    this.translateExplanation = data.explanation.translate;
                }
            }
            if (!!data.details) {
                this.details = data.details;
                this.showDetails = true;
            }
            if (!!data.error) {
                this.details = data.error.toString();
                this.showDetails = true;
            }
        }
    }

}
