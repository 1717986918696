import { Injectable } from '@angular/core';
import { Observable, Observer, of, throwError } from 'rxjs';

import { NGXLogger } from 'ngx-logger';

import {
    CognitoUser,
    CognitoUserPool,
    ICognitoUserPoolData,
    ICognitoUserData,
    CognitoUserAttribute,
    ICognitoUserAttributeData,
    ISignUpResult,
    AuthenticationDetails,
    IAuthenticationDetailsData,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';

export enum ForgotPasswordState {
    SUCCESS, INPUT_VERIFICATION_CODE,
}
export interface IUserForgotPasswordResult {
    state: ForgotPasswordState;
    data: any;
}

export enum AuthenticationResultState {
    SUCCESS, NEW_PASSWORD_REQUIRED,
}
/**
 * CAUTION: This structure is a little tricky because so many results can fly off of authenticateUser
 */
export interface IUserAuthenticationResult {
    state: AuthenticationResultState;
    session?: CognitoUserSession;        // SUCCESS
    userConfirmationNecessary?: boolean; // SUCCESS
    userAttributes?: any;                // NEW_PASSWORD_REQUIRED
    requiredAttributes?: any;            // NEW_PASSWORD_REQUIRED
}

/**
 * Basic Cognito Service
 *
 * Assumptions:
 * <ol>
 *   <li> AWS Cognito is configured to accept username or emailAddress.  Addulate presents email address in its UI and
 *        uses </li>
 * </ol>
 */
@Injectable({
    providedIn: 'root',
})
export class AwsCognitoService {

    constructor(
        private logger: NGXLogger,
    ) {
    }

    /**
     * Create new user in the pool.
     *
     * @param userId Should match the userID format in Addulate "user_[UUID]"
     * @param email Email address is the primary way users will log into the system
     * @param password Required (obviously)
     * @param phoneNumber Optional
     */
    public createUser(
        userId: string,
        email: string,
        password: string,
        phoneNumber: string,
    ): Observable<CognitoUser> {
        this.logger.info('AwsCognitoService.createUser()');
        if (!userId) { return throwError(new Error('precondition: userId not null')); }
        if (userId.length === 0) { return throwError(new Error('precondition: userId.length > 0')); }
        if (!email) { return throwError(new Error('precondition: email not null')); }
        if (email.length === 0) { return throwError(new Error('precondition: email.length > 0')); }
        if (!password) { return throwError(new Error('precondition: password not null')); }
        if (password.length === 0) { return throwError(new Error('precondition: password.length > 0')); }

        const username: string = userId; // Cognito uses username as a key, which is Addulate's userId

        let userAttributes: CognitoUserAttribute[] = [];
        let validationData: CognitoUserAttribute[];

        // email is required
        const dataEmail: ICognitoUserAttributeData = {
            Name: 'email',
            Value: email,
        };
        const attributeEmail: CognitoUserAttribute = new CognitoUserAttribute(dataEmail);
        userAttributes.push(attributeEmail);

        // phone is optional
        if (!!phoneNumber) {
            const dataPhoneNumber: ICognitoUserAttributeData = {
                Name: 'phone_number',
                Value: phoneNumber, // your phone number here with +country code and no delimiters in front
            };
            const attributePhoneNumber: CognitoUserAttribute = new CognitoUserAttribute(dataPhoneNumber);
            userAttributes.push(attributePhoneNumber);
        }

        // Envoke async operation with AWS Cognito
        return Observable.create((observer: Observer<CognitoUser>) => {
            const userPool: CognitoUserPool = this.getUserPool();
            userPool.signUp(username, password, userAttributes, validationData, (err: Error, result: ISignUpResult) => {
                if (err) {
                    observer.error(err);
                } else {
                    this.logger.info('user name is ' + result.user.getUsername());
                    observer.next(result.user);
                    observer.complete();
                }
            });
        });
    }

    /**
     * After a user is created, use this API (and the number sent via email or SMS) to confirm them.
     *
     * @param emailOrUserId
     * @param code the (typically 6-digit) numerical code received
     */
    public confirmNewUser(emailOrUserId: string, code: string): Observable<any> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }
        if (!code) { return throwError(new Error('precondition: code not null')); }
        if (code.length === 0) { return throwError(new Error('precondition: code.length > 0')); }

        return Observable.create((observer: Observer<any>) => {
            const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

            cognitoUser.confirmRegistration(code, true, (err: any, result: any) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next(result);
                    observer.complete();
                }
            });
        });
    }

    public resendConfirmationCode(emailOrUserId: string): Observable<any> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }

        return Observable.create((observer: Observer<any>) => {
            const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

            cognitoUser.resendConfirmationCode((err: any, result: any) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next(result);
                    observer.complete();
                }
            });
        });
    }

    /**
     *
     * @param emailOrUserId
     * @param oldPassword
     * @param newPassword
     */
    public changePassword(emailOrUserId: string, oldPassword: string, newPassword: string): Observable<any> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }
        if (!oldPassword) { return throwError(new Error('precondition: oldPassword not null')); }
        if (oldPassword.length === 0) { return throwError(new Error('precondition: oldPassword.length > 0')); }
        if (!newPassword) { return throwError(new Error('precondition: newPassword not null')); }
        if (newPassword.length === 0) { return throwError(new Error('precondition: newPassword.length > 0')); }

        return Observable.create((observer: Observer<any>) => {
            const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

            cognitoUser.changePassword(oldPassword, newPassword, (err: any, result: any) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next(result);
                    observer.complete();
                }
            });
        });
    }

    /**
     * Forgot your password? Get an email or SMS with a reset code.
     *
     * @param emailOrUserId to send the verificationCode
     * @returns result.state === 'INPUT' when to confirmPassword(), result.state==='SUCCESS' and close when finished.
     *
     * @see confirmPassword for second step in this workflow
     */
    public forgotPassword(emailOrUserId: string): Observable<IUserForgotPasswordResult> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }

        return Observable.create((observer: Observer<IUserForgotPasswordResult>) => {
            const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

            cognitoUser.forgotPassword({
                onSuccess: function (result: any): void { // returns after call to confirmPassword
                    observer.next({ state: ForgotPasswordState.SUCCESS, data: result });
                    observer.complete();
                },
                onFailure: function (err: Error): void {
                    observer.error(err);
                },
                inputVerificationCode(data: any): void { // signals time to submit
                    observer.next({ state: ForgotPasswordState.INPUT_VERIFICATION_CODE, data: data });
                    observer.complete();
                },
            });
        });
    }

    /**
     * Confirm new password from passwordReset using verification code
     *
     * @param emailOrUserId
     * @param verificationCode
     * @param newPassword
     * @returns
     *
     * @see forgotPassword for first part of this workflow to generate the verification code
     */
    public confirmPassword(emailOrUserId: string, verificationCode: string, newPassword: string): Observable<boolean> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }
        if (!verificationCode) { return throwError(new Error('precondition: verificationCode not null')); }
        if (verificationCode.length === 0) { return throwError(new Error('precondition: verificationCode.length > 0')); }
        if (!newPassword) { return throwError(new Error('precondition: newPassword not null')); }
        if (newPassword.length === 0) { return throwError(new Error('precondition: newPassword.length > 0')); }

        return Observable.create((observer: Observer<any>) => {
            const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => {
                    observer.next(true);
                    observer.complete();
                },
                onFailure: (err: Error) => {
                    observer.error(err);
                },
            });
        });
    }

    /**
     * Retrieve the current user from local storage
     */
    public loadSession(): Observable<CognitoUserSession> {
        const userPool: CognitoUserPool = this.getUserPool();
        const cognitoUser: CognitoUser = userPool.getCurrentUser();

        if (!cognitoUser) {
            return throwError(new Error('No current user'));
        } else {
            return Observable.create((observer: Observer<CognitoUserSession>) => {
                cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
                    if (!!err) {
                        observer.error(err);
                    } else {
                        observer.next(session);
                        observer.complete();
                    }
                });
            });
        }
    }

    /**
     * Authenticate user against Cognito
     *
     * @param emailOrUserId
     * @param password
     * @return complex structure that needs to be examined.
     */
    public authenticateUser(emailOrUserId: string, password: string): Observable<IUserAuthenticationResult> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }
        if (!password) { return throwError(new Error('precondition: password not null')); }
        if (password.length === 0) { return throwError(new Error('precondition: password.length > 0')); }

        const authenticationData: IAuthenticationDetailsData = {
            Username: emailOrUserId, // your username here
            Password: password, // your password here
        };
        const authenticationDetails: AuthenticationDetails = new AuthenticationDetails(authenticationData);

        const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

        return Observable.create((observer: Observer<any>) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session: CognitoUserSession, userConfirmationNeccessary: boolean) => {
                    observer.next({
                        state: AuthenticationResultState.SUCCESS,
                        session: session,
                        userConfirmationNeccessary: userConfirmationNeccessary,
                    });
                    observer.complete();
                },
                onFailure: (err: any) => {
                    observer.error(err);
                },
                newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
                    observer.next({
                        state: AuthenticationResultState.NEW_PASSWORD_REQUIRED,
                        userAttributes: userAttributes,
                        requiredAttributes: requiredAttributes,
                    });
                    observer.complete();
                },
            });
        });
    }

    public signOut(emailOrUserId: string): void {
        if (!emailOrUserId || emailOrUserId.length === 0) {
            return;
        }
        const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);
        cognitoUser.signOut();
    }

    public globalSignOut(emailOrUserId: string): Observable<string> {

        if (!emailOrUserId) { return throwError(new Error('precondition: emailOrUserId not null')); }
        if (emailOrUserId.length === 0) { return throwError(new Error('precondition: emailOrUserId.length > 0')); }
        const cognitoUser: CognitoUser = this.getCognitoUser(emailOrUserId);

        return Observable.create((observer: Observer<string>) => {
            cognitoUser.globalSignOut({
                onSuccess: (msg: string) => {
                    observer.next(msg);
                    observer.complete();
                },
                onFailure: (err: Error) => {
                    observer.error(err);
                },
            });
        });
    }

    public getAWSCredentials(session: CognitoUserSession): Observable<AWS.CognitoIdentityCredentials> {

        // this object is complicated because dynamic information embedded in the key
        let loginsObj: { [K: string]: any } = new Object();
        const region: string = environment.cognitoRegion;
        const userPoolId: string = environment.cognitoUserPoolId;
        loginsObj[`cognito-idp.${region}.amazonaws.com/${userPoolId}`] = session.getIdToken().getJwtToken();

        const awsCredentials: AWS.CognitoIdentityCredentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.cognitoDeusModusIdentityPoolId,
            Logins: loginsObj,
        }, {
                region: region,
            });
        if (awsCredentials.needsRefresh()) {
            return Observable.create((observer: Observer<AWS.CognitoIdentityCredentials>) => {
                awsCredentials.refresh((err: AWS.AWSError) => {
                    if (err) {
                        this.logger.error('failed credential refresh ', err.message);
                        observer.error(err);
                    } else {
                        this.logger.info('AWS credentials refreshed', awsCredentials.expireTime.toDateString());
                        observer.next(awsCredentials);
                        observer.complete();
                    }
                });
            });
        }
        return of(awsCredentials);
    }

    public getCurrentUser(): CognitoUser {
        const userPool: CognitoUserPool = this.getUserPool();
        if (!!userPool) {
            return userPool.getCurrentUser();
        } else {
            return undefined;
        }
    }

    private getUserPool(): CognitoUserPool {
        this.logger.info('getUserPool');
        const poolData: ICognitoUserPoolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId,
        };
        const userPool: CognitoUserPool = new CognitoUserPool(poolData);
        return userPool;
    }

    /**
     * Construct the CognitoUser
     *
     * @param emailOrUserID can be userID or email (because of how we configured AWS Cognito)
     */
    private getCognitoUser(emailOrUserId: string): CognitoUser {
        if (!emailOrUserId) {
            this.logger.trace('error: precondition fail: emailOrUserId cannot be null');
            return undefined;
        }
        this.logger.info('getCognitoUser');
        const userPool: CognitoUserPool = this.getUserPool();
        const userData: ICognitoUserData = {
            Username: emailOrUserId,
            Pool: userPool,
        };
        const cognitoUser: CognitoUser = new CognitoUser(userData);
        return cognitoUser;
    }
}
