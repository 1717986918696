import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, concat } from 'rxjs/operators';

import * as moment from 'moment-timezone';

import { HandshakeService } from './handshake.service';
import { ScreenService } from './screen.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { IScreen } from '../models/screen.model';
import { IHandshake } from '../models/handshake.model';
import { environment } from '../../environments/environment';

@Injectable()
export class PairingService {

    constructor(
        private handshake: HandshakeService,
        private screen: ScreenService,
        private profile: ProfileService,
    ) { }

    isPaired(hardwareId: string): Observable<boolean> {
        return of(!!hardwareId);
    }

    pairDevice(screen: IScreen, hardwareId: string): Observable<void> {
        const newPassword: string = this.generatePassword();
        const iotEndpoint: string = this.getIotEndpoint(screen);
        let handshake: IHandshake = <IHandshake>{
            hardwareId: hardwareId,
            screenId: screen.id,
            serviceType: environment.name,
            serviceUrl: environment.androidScreenUrl,
            token: newPassword,
            creatorId: ProfileService.profileSingleton().accountID,
            expirationDate: moment.utc().add(30, 'minutes').format(),
            iotEndpoint: iotEndpoint,
        };
        // console.log('handshake = ', handshake);

        let src2: Observable<void> = this.screen.pairScreen(screen.id, hardwareId, newPassword);
        let src1: Observable<void> = this.handshake.createItem(handshake)
            .pipe(
                map<any, void>((item: any) => {
                    // console.log(item);
                    return;
                }),
                concat(src2),
        );

        // TODO: pairing should wait until a positive confirmation or a timeout.
        return src1;
    }

    unpairDevice(screenId: string, hardwareId: string): Observable<void> {
        let src2: Observable<void> = this.screen.unpairScreen(screenId);
        let src1: Observable<void> = this.handshake.deleteItem(hardwareId)
            .pipe(concat(src2));
        return src1;
    }

    pollForHandshake(hardwareId: string, nRetries: number, intervalMSecs: number): Observable<boolean> {
        return this.handshake.pollForHandshake(hardwareId, nRetries, intervalMSecs);
    }

    pollForDeleted(hardwareId: string, nRetries: number, intervalMSecs: number): Observable<boolean> {
        return this.handshake.pollForDeleted(hardwareId, nRetries, intervalMSecs);
    }

    deleteHandshake(hardwareId: string): Observable<void> {
        return this.handshake.deleteItem(hardwareId);
    }

    private generatePassword(): string {
        const length: number = 12;
        const charset: string = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ023456789';
        const n: number = charset.length;
        let retVal: string = '';
        for (let i: number = 0; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    private getIotEndpoint(screen: IScreen): string {
        if (screen.timezone in environment.iotEndpoints) {
            return environment.iotEndpoints[screen.timezone];
        } else {
            return environment.iotEndpoints.default;
        }
    }
}
