import { Injectable } from "@angular/core";

import { NGXLogger } from "ngx-logger";

import { environment } from "../../../environments/environment";
import * as AWS from "aws-sdk";

@Injectable()
export class AwsS3Service {
  s3: AWS.S3;

  upload: AWS.S3.ManagedUpload; // ManagedUpload;

  allowedVideoTypes: any = {
    ".flv": "video/x-flv", // Flash
    ".mp4": "video/mp4", // MPEG-4
    ".m3u8": "application/x-mpegURL", // iPhone Index
    ".ts": "video/MP2T", // iPhone Segment
    ".3gp": "video/3gpp", // 3GP Mobile
    ".mov": "video/quicktime", // QuickTime
    ".avi": "video/x-msvideo", // A/V Interleave
    ".wmv": "video/x-ms-wmv", // Windows Media
  };

  allowedImageTypes: any = {
    ".jpg": "image/jpeg",
    ".jpeg": "image/jpeg",
    ".png": "image/png",
    ".gif": "image/gif",
  };

  constructor(private logger: NGXLogger) {
    AWS.config.update({
      region: environment.s3BucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.s3IdentityPoolId,
      }),
    });

    this.s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      // params: { Bucket: environment.s3BucketName },
    });
  }

  // returns a safe key name using only recommended characters
  public safeKeyName(input: string): string {
    const trimmed: string = input.trim();
    const noinnerpsace: string = trimmed.replace(" ", "_");
    const asciiPlus: string = noinnerpsace.replace(/([^A-Za-z1-9._-])/gu, "");
    if (asciiPlus.length > 10) {
      return asciiPlus;
    } else {
      return "D" + new Date().toISOString() + asciiPlus;
    }
  }

  public uploadFile(
    sourceFile: File,
    destBucket: string,
    destPath: string
  ): AWS.S3.ManagedUpload {
    let contentType: string = this.inferContentType(destPath);
    if (contentType !== sourceFile.type) {
      this.logger.warn(
        "contentType mismatch: ",
        contentType,
        " vs ",
        sourceFile.type
      );
    }

    this.logger.log(
      "copy local://" + sourceFile.name + " --> " + destBucket + "/" + destPath
    );
    this.upload = this.s3.upload({
      Bucket: destBucket,
      Key: destPath,
      Body: sourceFile,
      ContentType: contentType,
      ACL: "public-read",
    });
    return this.upload;
  }

  public copyFile(
    srcBucket: string,
    srcPath: string,
    destBucket: string,
    destPath: string
  ): AWS.Request<AWS.S3.CopyObjectOutput, AWS.AWSError> {
    this.logger.log(
      "copy " +
        srcBucket +
        "/" +
        srcPath +
        " --> " +
        destBucket +
        "/" +
        destPath
    );
    let contentType: string = this.inferContentType(destPath);
    let copy: any = this.s3.copyObject({
      Bucket: destBucket,
      Key: destPath,
      CopySource: srcBucket + "/" + srcPath,
      ACL: "public-read",
      ContentType: contentType,
    });
    return copy;
  }

  public uploadVideo(
    accountID: string,
    userID: string,
    localFile: File,
    extension: string
  ): any {
    // 1. compute the path (and checking)
    if (!accountID || accountID.length === 0) {
      this.logger.log("accountID cannot be empty");
      return;
    } else if (
      !/^account_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        accountID
      )
    ) {
      this.logger.log("accountID is not the correct format");
      return;
    } else if (!userID || userID.length === 0) {
      this.logger.log("userID cannot be empty");
      return;
    } else if (
      !/^user_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        userID
      )
    ) {
      this.logger.log("user_id is not the correct format");
      return;
    } else if (!(extension in this.allowedVideoTypes)) {
      this.logger.log("extension must be .mp4, .mov, or .wmv");
      return;
    }
    const videoKey: string = accountID + "/" + userID + "/" + localFile.name;

    // 2. make the request
    this.upload = this.s3.upload({
      Bucket: environment.s3BucketName,
      Key: videoKey,
      Body: localFile,
      ContentType: this.allowedVideoTypes[extension],
      ACL: "public-read",
    });

    return this.upload;
  }

  public uploadImage(
    accountID: string,
    userID: string,
    localFile: File,
    extension: string
  ): any {
    // 1. compute the path (and checking)
    if (!accountID || accountID.length === 0) {
      this.logger.log("accountID cannot be empty");
      return;
    } else if (
      !/^account_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        accountID
      )
    ) {
      this.logger.log("accountID is not the correct format:", accountID);
      return;
    } else if (!userID || userID.length === 0) {
      this.logger.log("userID cannot be empty");
      return;
    } else if (
      !/^user_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        userID
      )
    ) {
      this.logger.log("user_id is not the correct format");
      return;
    } else if (!(extension in this.allowedImageTypes)) {
      this.logger.log("extension must be .png, .gif, or .jpg");
      return;
    }
    const imageKey: string = accountID + "/" + userID + "/" + localFile.name;

    // 2. make the request
    this.upload = this.s3.upload({
      Bucket: environment.s3BucketName,
      Key: imageKey,
      Body: localFile,
      ContentType: this.allowedImageTypes[extension],
      ACL: "public-read",
    });
    return this.upload;
  }

  copyObject(
    srcBucket: string,
    srcKey: string,
    destBucket: string,
    destKey: string
  ): any {
    this.logger.log(
      "copy " + srcBucket + "/" + srcKey + " --> " + destBucket + "/" + destKey
    );
    let contentType: string = this.inferContentType(destKey);
    let copy: any = this.s3.copyObject({
      Bucket: destBucket,
      Key: destKey,
      CopySource: srcBucket + "/" + srcKey,
      ACL: "public-read",
      ContentType: contentType,
    });
    return copy;
  }

  deleteObject(bucket: string, key: string): any {
    if (!bucket) {
      bucket = environment.s3BucketName;
    }
    const deleteObj: any = this.s3.deleteObject({
      Bucket: bucket,
      Key: key,
    });
    return deleteObj;
  }

  abortLastUpload(): void {
    if (this.upload) {
      this.upload.abort();
    }
  }

  private inferContentType(name: string): string {
    const lower: string = name.toLocaleLowerCase();
    if (name.endsWith(".jpg") || name.endsWith(".jpeg")) {
      return "image/jpeg";
    } else if (name.endsWith(".png")) {
      return "image/png";
    } else if (name.endsWith(".gif")) {
      return "image/gif";
    } else if (name.endsWith(".mp4")) {
      // MPEG-4
      return "video/mp4";
    } else if (name.endsWith(".flv")) {
      // Flash
      return "video/x-flv";
    } else if (name.endsWith(".m3u8")) {
      // iPhone Index
      return "application/x-mpeg";
    } else if (name.endsWith(".ts")) {
      // iPhone Segment
      return "video/MP2T";
    } else if (name.endsWith(".3gp")) {
      // 3GP Mobile
      return "video/3gpp";
    } else if (name.endsWith(".mov")) {
      // QuickTime
      return "video/quicktime";
    } else if (name.endsWith(".avi")) {
      // A/V Interleave
      return "video/x-msvideo";
    } else if (name.endsWith(".wmv")) {
      // Windows Media
      return "video/x-ms-wmv";
    }
  }
}
