import { Component, OnInit, Injectable, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';
import { Observable } from 'rxjs';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { TdMediaService, StepState, IStepChangeEvent, TdLoadingService, LoadingType, LoadingMode, TdBytesPipe } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService } from '@ngx-translate/core';

import { VgAPI } from 'videogular2/core';

import { BaseEditor } from '../../base/base-editor';
import { FileUploadInfo } from './file-upload-info';
import { IVideo, VideoUtils } from '../../models/video.model';
import { IDataArray, IRemoteReference } from '../../models/data-array.model';
import { VideoService, AwsS3Service } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';

import { ProfileService, ProfileSingleton } from '../../auth/profile.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class CanDeactivateMediaVideoEditor implements CanDeactivate<MediaVideoEditorComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: MediaVideoEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-media-video-editor',
    templateUrl: './media-video-editor.component.html',
    styleUrls: ['./media-video-editor.component.scss'],
})
export class MediaVideoEditorComponent extends BaseEditor implements OnInit, AfterViewInit {

    private readonly DEFAULT_IMAGE_PATH: string = 'assets/default_video_thumbnail.png';
    private joinCount: number = 0;

    @ViewChild('myForm') myForm: NgForm;
    @ViewChild('videoTitleControl') videoTitleControl: NgModel;

    // Determines if component is creating a new video, or editing existing one
    //  == 'add' for new videos
    //  == video.id for editing existing videos.
    //  @see ngOnInit()
    action: string;
    cardTitle: string;

    // state of the individual steps
    stepState1: StepState = StepState.None; // load video
    stepState2: StepState = StepState.None; // load title screen image
    stepState3: StepState = StepState.None; // set metadata

    //
    // Video model
    //
    video: IVideo;

    //
    // Form data, matches attributes of a Video model
    //
    id: string;  // id of the video object
    title: string;  // title of the
    description: string;
    thumbnailUrl: string;
    contentUrl: string;
    renditions: any;
    width: number;
    height: number;
    durationSecs: number;
    bitrateMbps: number;
    fileSizeBytes: number;
    mimeType: string;
    aspectRatio: string;

    videoUpload: FileUploadInfo = new FileUploadInfo();
    imageUpload: FileUploadInfo = new FileUploadInfo();

    vgAPI: VgAPI; // videogular2 api

    //
    // BaseEditor overrides
    //

    // true iff the editor has changed anything that needs saving
    get dirty(): boolean {
        return this.myForm.dirty ||
            this.videoUpload.dirty ||
            this.imageUpload.dirty;
    }

    // true iff the editor is in a valid state (pristine or dirty)
    get valid(): boolean {
        return this.stepState1 === StepState.Complete &&
            // this.stepState2 === StepState.Complete &&
            this.myForm.valid;
    }

    constructor(
        private videoService: VideoService,
        private route: ActivatedRoute,
        public media: TdMediaService,
        private s3: AwsS3Service,
        private translate: TranslateService,
        private bytesPipe: TdBytesPipe,
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        super(router, dialog, snackBar, loadingService, logger);
        this.backPath = ['/media'];
    }

    //
    // establishes this.action, which remains constant for duration of view
    // Also loads Video data for form (if appropriate).
    //
    ngOnInit(): void {
        this.route.url.subscribe(
            (url: any) => {
                this.logger.log('MediaVideoEditorComponent.ngOnInit: url=', url);
                this.action = (url.length > 1 ? url[1].path : 'add');
                if (this.action === 'add') {
                    this.cardTitle = 'Media.VideoEditor.CardTitle.Create';
                } else {
                    this.loadData();
                    this.cardTitle = 'Media.VideoEditor.CardTitle.Modify';
                }
            },
            (err: any) => {
                this.logger.warn('MediaVideoEditor.onInit()', err);
                this.snackBar.open('Routing Error', 'Ok');
            });
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    //
    // These are the two main workflows
    //
    get isNewVideoWorkflow(): boolean {
        return this.action === 'add';
    }

    get isEditVideoWorkflow(): boolean {
        return this.action.indexOf('video_') === 0;
    }

    //
    // These getters are used in ngIf's to show/hide div.
    //
    get showLoadVideoPanel(): boolean {
        return this.isNewVideoWorkflow && !this.contentUrl;
    }

    get showReviewVideoPanel(): boolean {
        return !this.showLoadVideoPanel;
    }

    get showLoadImagePanel(): boolean {
        return this.isNewVideoWorkflow && !this.thumbnailUrl;
    }

    get showReviewImagePanel(): boolean {
        return !this.showLoadImagePanel;
    }

    get showEditVideoDetailsPanel(): boolean {
        return true;
    }

    //
    // callbacks from loadVideoPanel
    //
    onSelectVideoFileEvent(file: File): void {
        this.videoUpload.selectFile(file, 'Media.VideoEditor.Step1.UploadMsg.2Upload');
        if (!this.myForm.touched) {
            let terms: string[] = file.name.split('.');
            terms.pop();
            this.title = terms.join('.').replace('_', ' ');
            this.videoTitleControl.control.setValue(this.title);
            this.videoTitleControl.control.markAsTouched();
            this.videoTitleControl.control.updateValueAndValidity();
        }
        this.logger.log(file);
    }

    onUploadVideoFileEvent(file: File): void {
        this.videoUpload.statusMessage = 'Media.VideoEditor.Step1.UploadMsg.3Processing';
        // const self: this = this;
        const ingestBucket: string = environment.s3BucketName;
        const ingestPath: string = [
            ProfileService.profileSingleton().accountID,
            ProfileService.profileSingleton().userID,
            this.s3.safeKeyName(file.name),
        ].join('/');

        this.videoUpload.managedUpload = this.s3.uploadFile(file, ingestBucket, ingestPath);
        this.videoUpload.managedUpload
            .on('httpUploadProgress', (evt: any) => {
                this.videoUpload.progress(evt.loaded, evt.total, this.bytesPipe,
                    'Media.VideoEditor.Step1.UploadMsg.4UploadedNBytes');
            })
            .send((err: any, data: any) => {
                if (err) {
                    this.logger.warn('Storage.uploadFile(video)', err);
                    this.videoUpload.error(err);
                    this.showError(err);
                } else {
                    this.videoUpload.complete(data, 'Media.VideoEditor.Step1.UploadMsg.5Complete');
                    this.contentUrl = data.Location; // ingest bucket location
                    this.fileSizeBytes = this.videoUpload.localFile.size;
                    this.mimeType = this.videoUpload.localFile.type;
                    console.log(data.Location)
                    this.aspectRatio = VideoUtils.aspectRatio(data.Location);
                    console.log(this.aspectRatio)

                    this.renditions = [{
                        url: this.contentUrl,
                        type: this.videoUpload.localFile.type,
                    }];
                    this.logger.log('Upload success');
                    this.stepState1 = StepState.Complete;
                }
                this.videoUpload.fractionUploaded = 0;
                this.logger.log('upload complete');
            });
    }

    onVideoUploadCancelledEvent(): void {
        this.videoUpload.abort();
        this.videoUpload.reset('Media.VideoEditor.Step1.UploadMsg.1Choose');
    }

    //
    // callbacks from loadImagePanel
    //
    onSelectImageFileEvent(file: File): void {
        this.imageUpload.selectFile(file, 'Media.VideoEditor.Step2.UploadMsg.2Upload');
        this.logger.log(file);
    }

    onUploadImageFileEvent(file: File): void {
        this.imageUpload.statusMessage = 'Media.VideoEditor.Step2.UploadMsg.3Processing';
        const self: this = this;
        const ingestBucket: string = environment.s3BucketName;
        const ingestPath: string = [
            ProfileService.profileSingleton().accountID,
            ProfileService.profileSingleton().userID,
            this.s3.safeKeyName(file.name),
        ].join('/');

        this.imageUpload.managedUpload = this.s3.uploadFile(file, ingestBucket, ingestPath);
        this.imageUpload.managedUpload
            .on('httpUploadProgress', (evt: any) => {
                this.imageUpload.progress(evt.loaded, evt.total,
                    this.bytesPipe, 'Media.VideoEditor.Step2.UploadMsg.4UploadedNBytes');
            })
            .send((err: any, data: any) => {
                if (err) {
                    this.logger.warn('StorageService.upload(thumbnail)', err);
                    self.imageUpload.error(err);
                } else {
                    this.imageUpload.complete(data, 'Media.VideoEditor.Step1.UploadMsg.5Complete');
                    this.thumbnailUrl = data.Location;
                    this.logger.log('Upload success');
                    this.stepState2 = StepState.Complete;
                }
                self.imageUpload.fractionUploaded = 0;
                this.logger.log('upload complete');
            });
    }

    onImageUploadCancelledEvent(): void {
        this.imageUpload.abort();
        this.imageUpload.reset('Media.VideoEditor.Step2.UploadMsg.1Choose');
    }

    // NOTE: for all events available to videongular2 see https://github.com/videogular/videogular2/blob/master/docs/using-the-api.md

    onPlayerReadyEvent(api: VgAPI): void {

       
        this.vgAPI = api;
        const self: this = this;
        // this.vgAPI.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
        //     ($event) => { 
        //   this.durationSecs=this.vgAPI.duration;
        //   console.log("API  "+this.vgAPI.duration);
        //   });
        // setTimeout(function () {
        //     const videoElem: HTMLVideoElement = <HTMLVideoElement>document.getElementById('singleVideo');
        //     self.height = videoElem.offsetHeight;
        //     self.width  = videoElem.offsetWidth;
        //     videoElem.addEventListener('loadedmetadata', function() {
        //         var video_duration = videoElem.duration.toFixed(2);
        //         console.log(videoElem.duration.toFixed(2))
        //     });
        //  });
         

        this.vgAPI.getDefaultMedia().subscriptions.ended.subscribe(
            (data: any) => {
                console.log(data)
                this.logger.log('ended', data);
            });

        this.vgAPI.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
            (data: any) => {
                console.log("video data",data)
                this.logger.log('loaded', data);
                self.width = data.target.videoWidth;
                self.height = data.target.videoHeight;
                self.durationSecs = data.target.duration.toFixed(2);
                // this.aspectRatio = VideoUtils.aspectRatio(data.target);
            });

    }

    onMetadata(e, video) {
        console.log('metadata: ', e);
        console.log('duration: ', this.durationSecs = video.duration);
      }

    onUserClickedSave(): any {
        this.logger.log('title=', this.title);
        this.myForm.control.markAsPending();
        this.showSpinner();

        let video: IVideo = <IVideo>{
            id: this.id,
            title: this.title,
            description: this.description,
            contentUrl: this.contentUrl, // ingest location
            thumbnailUrl: this.thumbnailUrl, // ingest location or undefined
            width: this.width,
            height: this.height,
            durationSecs: this.durationSecs,
            bitrateMbps: this.bitrateMbps,
            fileSizeBytes: this.fileSizeBytes,
            mimeType: this.mimeType,
        };
        if (this.action === 'add') {
            this.videoService.create(video).subscribe(
                (videoRef: IRemoteReference<IVideo>) => {
                    this.id = videoRef.id;
                    this.finalizeCreate(videoRef);
                },
                (err: any) => {
                    this.logger.warn('VideoService.create()', err);
                    this.myForm.control.markAsDirty();
                    this.showError(err);
                },
            );
        } else {
            this.videoService.update(video).subscribe(
                (data: void) => {
                    this.myForm.control.markAsPristine();
                    this.goBack();
                },
                (err: any) => {
                    this.logger.warn('VideoService.create()', err);
                    this.myForm.control.markAsDirty();
                    this.showError(err);
                },
            );
        }
    }

    onUserClickedCancel(): void {
        this.goBack();
    }

    /*
	 * After a new record is created, we need to copy video file
	 * over using video_ID recieved from REST API. Same with the image.
	 */
    private finalizeCreate(videoRef: IRemoteReference<IVideo>): void {
        this.videoUpload.statusMessage = 'Media.VideoEditor.Finalize.1Commiting';
        const self: this = this;
        this.joinCount = 0;
        const videoTargetKey: string = ProfileService.profileSingleton().effectiveAccountID + '/' + this.id + this.videoUpload.extension;

        this.s3.copyObject(
            this.videoUpload.data.Bucket,
            this.videoUpload.data.Key,
            this.videoUpload.data.Bucket,
            videoTargetKey,
        )
            .on('success', (data: any) => {
                this.videoUpload.statusMessage = 'Media.VideoEditor.Finalize.2Updating';
                let bucketURL: string = data.request.httpRequest.endpoint.href + data.request.httpRequest.path;
                // let cloudfrontURL: string = data.request.httpRequest.endpoint.protocol + '/' + data.request.httpRequest.path;
                // this.contentUrl = cloudfrontURL;
            })
            .on('error', (err: any) => {
                this.logger.warn('StorageService.copy(video)', err);
                self.videoUpload.statusMessage = 'Error: ' + err;
                this.showError(err);
            })
            .on('complete', (response: any) => {
                self.checkReturn();
            })
            .send();

        if (!this.imageUpload.data) { // if user didn't specify image, use this as a default
            this.imageUpload.data = {
                Bucket: this.videoUpload.data.Bucket,
                Key: this.DEFAULT_IMAGE_PATH,
                ETag: undefined,
                Location: undefined,
            };
            this.imageUpload.extension = '.' + this.DEFAULT_IMAGE_PATH.split('.').pop();
        }
        const thumbnailTargetKey: string = ProfileService.profileSingleton().effectiveAccountID + '/' +
            videoRef.id + '_thumb' + this.imageUpload.extension;
        this.s3.copyObject(
            this.imageUpload.data.Bucket,
            this.imageUpload.data.Key,
            this.imageUpload.data.Bucket,
            thumbnailTargetKey,
        )
            .on('success', (data: any) => {
                this.imageUpload.statusMessage = 'Media.VideoEditor.Finalize.2Updating';
                let bucketURL: string = data.request.httpRequest.endpoint.href + data.request.httpRequest.path;
                // let cloudfrontURL: string = data.request.httpRequest.endpoint.protocol + '/' + data.request.httpRequest.path;
                // this.thumbnailUrl = cloudfrontURL;
            })
            .on('error', (err: any) => {
                this.logger.warn('StorageService.copy(thumbnail)', err);
                self.imageUpload.statusMessage = 'Error: ' + err;
                this.showError(err);
            })
            .on('complete', (response: any) => {
                self.checkReturn();
            })
            .send();

    }

    private loadData(): void {
        this.stepState1 = StepState.Complete;
        this.stepState2 = StepState.Complete;
        this.route.params.subscribe((params: { id: string }) => {
            this.logger.log('MediaVideoEditorComponent.ngOnInit: params=', params);
            let videoID: string = params.id;
            this.videoService.getById(videoID).subscribe((video: IVideo) => {
                this.logger.log('service returned=', video);
                this.video = video;
                this.id = video.id;
                this.title = video.title;
                this.description = video.description;
                this.thumbnailUrl = video.thumbnailUrl;
                this.contentUrl = video.contentUrl;
                this.mimeType = video.mimeType;
                this.renditions = [{
                    url: this.contentUrl,
                    type: 'video/mp4',
                }];
                this.width = video.width;
                this.height = video.height;
                this.durationSecs = video.durationSecs;
                this.bitrateMbps = video.bitrateMbps;
                this.fileSizeBytes = video.fileSizeBytes;
                this.aspectRatio = VideoUtils.aspectRatio(video);
            });
        });
    }

    // now that the files have been successfully moved, we update the database.
    private checkReturn(): void {
        if (++this.joinCount !== 2) {
            return;
        }
        let video: IVideo = <IVideo>{
            id: this.id,
            title: this.title,
            description: this.description,
            thumbnailUrl: this.thumbnailUrl,
            contentUrl: this.contentUrl,
            width: this.width,
            height: this.height,
            durationSecs: this.durationSecs,
            bitrateMbps: this.bitrateMbps,
            fileSizeBytes: this.fileSizeBytes,
            mimeType: this.mimeType,
        };
        this.aspectRatio = VideoUtils.aspectRatio(video);
        this.videoService.update(video).subscribe(
            (data: void) => {
                this.myForm.control.markAsPristine();
                this.videoUpload.dirty = false;
                this.imageUpload.dirty = false;

                this.myForm.reset({
                    title: this.title,
                    description: this.description,
                });
                this.goBack();
            },
            (err: any) => {
                this.logger.warn('finalizing video metadata', err);
                this.showError(err);
            },
        );
    }
}
