import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment-timezone';

const FORMAT_NO_TIMEZONE: string = 'YYYY-MM-DD[T]HH:mm:ss';

@Component({
    selector: 'gk-datetimelocal-picker',
    template: `
    <form [formGroup]="localFormGroup">
        <mat-datepicker-toggle [for]="picker" [disabled]="disabled"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-form-field>
            <input formControlName="dateInput" matInput [matDatepicker]="picker" [placeholder]="datePlaceholder"
                (dateInput)="onDateInput($event)" (dateChange)="onDateChange($event)" [disabled]="disabled">
        </mat-form-field>

        <mat-icon>access_time</mat-icon>
        <mat-form-field>
            <input formControlName="timeInput" matInput [placeholder]="timePlaceholder" type="time" step="5"
                (change)="onTimeChange($event)" (update)="onTimeUpdate($event)" [disabled]="disabled">
        </mat-form-field>
    </form>`,
    styleUrls: ['./datetime-local.component.scss'],
})
export class DatetimeLocalComponent implements AfterViewInit {

    datetime: moment.Moment;

    @Input() parent: FormGroup;

    @Input() controlName: string;

    @Input() datePlaceholder: string;

    @Input() timePlaceholder: string;

    @Input() disabled: boolean;

    @Output() dateChangeEvent: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

    localFormGroup: FormGroup = this.fb.group({
        dateInput: '',
        timeInput: '',
    });

    constructor(
        private fb: FormBuilder,
        private logger: NGXLogger,
    ) {
        this.datetime = moment(new Date());
    }

    ngAfterViewInit(): void {
        this.parent.get(this.controlName).valueChanges.forEach(
            (newDatetimeString: string): void => {
                this.refreshDisplay(newDatetimeString);
            },
        );
        let datetimeString: string = this.parent.get(this.controlName).value;
        this.refreshDisplay(datetimeString);
    }

    // change from parent needs to be reflected in local group
    refreshDisplay(datetimeString: string): void {
        this.datetime = moment(datetimeString);
        if (this.datetime.isValid()) {
            let dateString: string = this.datetime.format('YYYY-MM-DD');
            let timeString: string = this.datetime.format('HH:mm:ss');
            setTimeout(() => this.localFormGroup.patchValue({
                dateInput: moment(dateString).format(), // workaround: date may reinterpret as UTC, and shift unless we fully qualify the date
                timeInput: timeString,
            }), 0);
        } else {
            this.logger.log('cannot refresh display of invalid datetime string ', datetimeString);
        }
    }

    // change from UI needs to pass back to parent
    onDateInput($event: any): void {
        this.logger.log('onDateInput ', this.datePlaceholder, $event);
        let m: moment.Moment = moment($event.value);
        this.setDate(m);
        this.updateParent();
    }

    // change from UI needs to pass back to parent
    onDateChange($event: any): void {
        this.logger.log('onDateChange ', this.datePlaceholder, $event);
        let m: moment.Moment = moment($event.value);
        this.setDate(m);
        this.updateParent();
    }

    onTimeChange($event: any): void {
        this.logger.log('onTimeChange ', this.timePlaceholder, $event);
        let m: moment.Moment = moment($event.target.value, 'HH:mm:ss');
        this.setTime(m);
        this.updateParent();
    }

    onTimeUpdate($event: any): void {
        this.logger.log('onTimeUpdate ', this.timePlaceholder, $event);
        let m: moment.Moment = moment($event.target.value, 'HH:mm:ss');
        this.setTime(m);
        this.updateParent();
    }

    private setDate(m: moment.Moment): void {
        if (m.isValid()) { // get everything in local time, because that's how future dates work best.
            this.datetime = moment({
                year: m.year(),
                month: m.month(),
                date: m.date(),
                hour: this.datetime.hour(),
                minute: this.datetime.minute(),
                second: this.datetime.second(),
            });
        }
    }

    private setTime(m: moment.Moment): void {
        if (m.isValid()) {
            this.datetime = moment({
                year: this.datetime.year(),
                month: this.datetime.month(),
                date: this.datetime.date(),
                hour: m.hour(),
                minute: m.minute(),
                second: m.second(),
            });
        }
    }

    private updateParent(): void {
        if (!!this.controlName) {
            let formattedString: string = this.datetime.format('YYYY-MM-DD[T]HH:mm:ss');
            let tmp: any = {};
            tmp[this.controlName] = formattedString;
            this.parent.patchValue(tmp);
            this.dateChangeEvent.emit(this.datetime);
        }
    }

}
