import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ThemingService {

    // Observable source
    private static themeChangeSubject: Subject<string> = new Subject<string>();

    // Observable stream
    themeChanged: Observable<string> = ThemingService.themeChangeSubject.asObservable();

    // Service message commands
    changeTheme(newTheme: string): void {
        ThemingService.themeChangeSubject.next(newTheme);
    }

}
