import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { L10nService } from '../l10n/l10n.service';
import { AccountService } from '../services';
import { IAccount } from '../models/account.model';
import { IDataArray } from '../models/data-array.model';

import { ProfileService, ProfileSingleton } from '../auth/profile.service';

@Component({
    selector: 'gk-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss'],
    viewProviders: [AccountService],
    providers: [L10nService],
})

export class AccountsComponent implements OnInit, OnDestroy {

    private subscription: any;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    readonly ADDULATE_ROOT_ID: string = '00000000-0000-4000-b000-000000000000';

    data: IAccount[] = [];

    hasAdminRole: boolean = false;
    hasSubaccountRole: boolean = false;

    columns: ITdDataTableColumn[] = [
        { name: 'name', label: 'Name', tooltip: 'Acc' },
        { name: 'isActive', label: 'Status' },
        { name: 'id', label: '' },
    ];

    lookup: Object = {
        name: 'Accounts.ColHeader.Name',
        isActive: 'Accounts.ColHeader.Status',
    };

    filteredData: IAccount[] = this.data;
    filteredTotal: number = this.data.length;

    selectedRows: IAccount[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'name';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    constructor(
        private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _accountService: AccountService,
        private _snackBarService: MatSnackBar,
        private translate: TranslateService,
        private logger: NGXLogger,
    ) { }

    ngOnInit(): void {
        this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadAccounts();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAccountChangeEvent(event: any): void {
        this.data = [];
        this.loadAccounts();
    }

    sort(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    search(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    page(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    filter(): void {
        let newData: any[] = this.data;
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
        this.hasAdminRole = ProfileService.profileSingleton().hasAdminRole;
        this.hasSubaccountRole = ProfileService.profileSingleton().hasSubaccountRole;
       
    }

    loadAccounts(): void {
        this._accountService.getAll().subscribe(
            (data: IDataArray<IAccount>) => {
                this.data = data.data.filter((x: any) => x.id !== this.ADDULATE_ROOT_ID);
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded accounts', this.data);
                this.filter();
            },
        );
    }

    reloadAccounts(): void {
        this.data = [];
        this.filter();
        this.loadAccounts();
    }

    deleteAccount(id: string): void {

        if (id === this.ADDULATE_ROOT_ID) {
            this._snackBarService.open('Cannot delete Addulate Root IAccount', 'Ok', {duration: 3000});
            return;
        }

        let accountName: string = this.data.find((x: IAccount) => x.id === id).name;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete this account?' })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this._accountService.delete(id).subscribe(
                        (data: void) => {
                            this.data = this.data.filter((account: IAccount) => {
                                return account.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((account: IAccount) => {
                                return account.id !== id;
                            });
                            this._snackBarService.open('Account deleted', 'Ok', {duration: 3000});
                        }, (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this.translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }
}
