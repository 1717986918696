import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment-timezone';
import * as RR from 'rrule';

import { IScheduledContent } from '../../models/scheduled-content.model';
import { IPlaylist } from '../../models/playlist.model';
import { IChannel } from '../../models/channel.model';
import { CustomValidators } from '../../validators/custom.validators';

@Component({
    selector: 'gk-schedule-dialog',
    templateUrl: './schedule-dialog.component.html',
    styleUrls: ['./schedule-dialog.component.scss'],
})
export class ScheduleDialogComponent implements OnInit {

    private _scheduledContent: IScheduledContent;

    private _isCreateNewSchedule: boolean;

    playlist: IPlaylist;
    channel: IChannel;

    title: string = 'Schedule Playlist on Channel';

    maxMultiples: number; // the maximum number of times the playlist can be played within 24 hour period

    form: FormGroup = this.fb.group({
        colorName: 'yellow',
        startDatetime: ['', [Validators.required, CustomValidators.isDatetimeStr]],
        durationRule: ['once', [Validators.required, CustomValidators.isInEnum(['once', 'multi', 'custom'])]],
        nRepetitions: [2, [Validators.required, Validators.min(1), CustomValidators.isInteger]],
        duration: ['', [Validators.required, CustomValidators.isDuration]],
        endDatetime: ['', [Validators.required, CustomValidators.isDatetimeStr]],
        repeatRule: ['never', [Validators.required, CustomValidators.isInEnum(['never', 'daily', 'weekly', 'monthly', 'annually'])]],

        // could be 'daily', 'weekly', 'monthly' or 'annually',
        dailyRules: this.fb.group({
            nDays: [1, [Validators.required, Validators.min(1), Validators.max(365), CustomValidators.isInteger]],
        }),
        weeklyRules: this.fb.group({
            nWeeks: [1, [Validators.required, Validators.min(1), Validators.max(52), CustomValidators.isInteger]],
            onSunday: true,
            onMonday: false,
            onTuesday: false,
            onWednesday: false,
            onThursday: false,
            onFriday: false,
            onSaturday: false,
        }, [CustomValidators.weeklyRulesHasAtLeastOneDay]),
        monthlyRules: this.fb.group({
            nMonths: [1, [Validators.required, Validators.min(1), Validators.max(12), CustomValidators.isInteger]],
            dayOf: ['month', [Validators.required, CustomValidators.isInEnum(['month', 'week', 'weekMonthEnd'])]],
        }),
        annualRules: this.fb.group({
            nYears: [1, [Validators.required, Validators.min(1), Validators.max(10), CustomValidators.isInteger]],
        }),
        completionRules: this.fb.group({
            until: ['forever', [Validators.required, CustomValidators.isInEnum(['forever', 'count', 'date'])]],
            count: [1, [Validators.required, Validators.min(1), CustomValidators.isInteger]],
            date: ['', [Validators.required, CustomValidators.isDateOrDatetimeStr]],
        }),
    });

    durationRules: any = [
        { code: 'once', name: 'playlist completes' },
        { code: 'multi', name: 'playlist has repeated' },
        { code: 'custom', name: 'fixed amount of time' },
    ];

    repeatRules: any = [
        { code: 'never', name: 'Never' },
        { code: 'daily', name: 'Daily' },
        { code: 'weekly', name: 'Weekly' },
        { code: 'monthly', name: 'Monthly' },
        { code: 'annually', name: 'Annually' },
    ];

    get startSublabel(): string {
        return this.start.format('LLLL') + ' (local time)';
    }

    durationSublabel: string;

    repeatSublabel: string;

    get scheduledContent(): IScheduledContent {
        let value: IScheduledContent = Object.assign({}, this._scheduledContent);
        value.colorName = this.colorName;
        value.startDatetime = this.start.format('YYYY-MM-DD[T]HH:mm:ss');
        const durationRule: string = this.durationRule;
        if (durationRule === 'once') {
            value.repetitions = 1;
        } else if (durationRule === 'multi') {
            value.repetitions = this.nRepetitions;
        } else if (durationRule === 'custom') {
            value.repetitions = -1;
        }
        value.duration = this.duration.toISOString();
        let rrule: RR.RRule = this.exportRRule();
        value.recurranceRule = rrule ? rrule.toString() : '';
        return value;
    }

    set scheduledContent(scheduledContent: IScheduledContent) {
        this._scheduledContent = Object.assign({}, scheduledContent); // clone the object
        this.colorName = scheduledContent.colorName;
        const repetitions: number = Math.floor(scheduledContent.repetitions);
        if (repetitions === 1) {
            this.durationRule = 'once';
            this.duration = moment.duration({ seconds: this.playlist.durationSecs });
        } else if (scheduledContent.repetitions > 1) {
            this.durationRule = 'multi';
            this.nRepetitions = repetitions;
            this.duration = moment.duration({ seconds: this.playlist.durationSecs * repetitions });
        } else {
            this.durationRule = 'custom';
            this.duration = moment.duration(scheduledContent.duration);
        }
        if (this._scheduledContent.recurranceRule) {
            let rrule: RR.RRule = RR.RRule.fromString(this._scheduledContent.recurranceRule);
            this.start = moment(rrule.options.dtstart);
            this.rrule = rrule;
        } else {
            this.start = moment(scheduledContent.startDatetime);
        }
        this.end = this.start.clone().add(this.duration);
    }

    get colorName(): string {
        return this.form.get('colorName').value;
    }

    set colorName(colorName: string) {
        this.form.patchValue({
            colorName: colorName,
        });
    }

    get start(): moment.Moment {
        const datetimeString: string = this.form.get('startDatetime').value;
        return moment(datetimeString);
    }

    set start(m: moment.Moment) {
        this.logger.log('set start() = ', m.format('LLL'));
        this.form.patchValue({
            startDatetime: m.format('YYYY-MM-DD[T]HH:mm:ss'),
        });
        this.onStartDatetimeChanged(undefined);
    }

    get end(): moment.Moment {
        const datetimeString: string = this.form.get('endDatetime').value;
        return moment(datetimeString);
    }

    set end(m: moment.Moment) {
        this.logger.log('set end() = ', m.format('LLL'));
        this.form.patchValue({
            endDatetime: m.format('YYYY-MM-DD[T]HH:mm:ss'),
        });
        this.onEndDatetimeChanged(undefined);
    }

    get duration(): moment.Duration {
        const durationString: string = this.form.get('duration').value;
        return moment.duration(durationString);
    }

    set duration(d: moment.Duration) {
        this.logger.log('set duration() = ', d.humanize());
        this.form.patchValue({
            duration: d.toISOString(),
        });
    }

    get durationRule(): string {
        return this.form.get('durationRule').value;
    }

    set durationRule(rule: string) {
        this.form.patchValue({
            durationRule: rule,
        });
    }

    get nRepetitions(): number {
        return this.form.get('nRepetitions').value;
    }

    set nRepetitions(value: number) {
        if (value > 1) {
            this.form.patchValue({
                nRepetitions: value,
            });
        }
    }

    get repeatRule(): string {
        return this.form.get('repeatRule').value;
    }

    set repeatRule(rule: string) {
        this.form.patchValue({
            repeatRule: rule,
        });
    }

    get dailyRules(): AbstractControl {
        return this.form.get('dailyRules');
    }

    get weeklyRules(): AbstractControl {
        return this.form.get('weeklyRules');
    }

    get monthlyRules(): AbstractControl {
        return this.form.get('monthlyRules');
    }

    get annualRules(): AbstractControl {
        return this.form.get('annualRules');
    }

    get completionRules(): AbstractControl {
        return this.form.get('completionRules');
    }

    get completionRulesUntil(): AbstractControl {
        return this.completionRules.get('until');
    }

    get completionRulesCount(): AbstractControl {
        return this.completionRules.get('count');
    }

    get completionRulesDate(): AbstractControl {
        return this.completionRules.get('date');
    }

    get rrule(): RR.RRule {
        const rrule: RR.RRule = this.exportRRule();
        return rrule;
    }

    set rrule(rrule: RR.RRule) {
        this.importRRule(rrule);
    }

    get isCreateNewSchedule(): boolean {
        return this._isCreateNewSchedule;
    }

    set isCreateNewSchedule(isCreateNewSchedule: boolean) {
        if (isCreateNewSchedule) {
            this.title = 'Schedule Playlist on Channel';
        } else {
            this.title = 'Edit ' + moment(this._scheduledContent.startDatetime).format('L') + ' Schedule For Playlist on Channel';
        }
        this._isCreateNewSchedule = isCreateNewSchedule;
    }

    constructor(
        public dialogRef: MatDialogRef<ScheduleDialogComponent>,
        private fb: FormBuilder,
        private logger: NGXLogger,
    ) {
        this.form.get('durationRule').valueChanges.forEach((newRule: string) => {
            this.enableDisableFormByDurationRule(newRule);
        });
        this.form.get('repeatRule').valueChanges.forEach((newRule: string) => {
            this.enableDisableFormByRepeatRule(newRule);
        });
        this.form.get('completionRules').get('until').valueChanges.forEach((newRule: any) => {
            this.enableDisableFormByCompletionRule(newRule);
        });
    }

    ngOnInit(): void {
        // set some defaults now that the triggers are set.
        if (this.isCreateNewSchedule) {
            setTimeout(() => {
                this.durationRule = 'once';
                this.repeatRule = 'never';
                this.completionRules.patchValue({
                    until: 'forever',
                });
            }, 0);
        } else {
            this.enableDisableFormByDurationRule(this.durationRule);
            this.enableDisableFormByRepeatRule(this.repeatRule);
            this.enableDisableFormByCompletionRule(this.completionRulesUntil.value);
        }
    }

    colorChanged($event: string): void {
        this.colorName = $event;
    }

    onCancelPressed(): void {
        this.dialogRef.close(undefined);
    }

    onSavePressed(): void {
        this.dialogRef.close(this.scheduledContent);
    }

    onStartDatetimeChanged($event: any): void {
        // start changed, keep duration constant, modify end.
        this.logger.log('onStartDatetimeChanged', $event);
        const start: moment.Moment = this.start;
        const duration: moment.Duration = this.duration;
        if (start.isValid() && duration) {
            this.end = start.add(duration);
        }
    }

    onDurationChanged($event: any): void {
        // duration changed, keep start constant, modify end.
        this.logger.log('onDurationChanged', $event);
        const start: moment.Moment = this.start;
        const duration: moment.Duration = this.duration;
        if (start.isValid() && duration) {
            this.end = start.add(duration);
        }
        this.updateDurationSublabel();
    }

    onRepetitionsChanged($event: any): void {
        // number of repetitions changed.  This is another way of changing duration
        this.duration = moment.duration(this.playlist.durationSecs * this.nRepetitions, 'seconds');
        this.onDurationChanged(undefined);
    }

    onEndDatetimeChanged($event: any): void {
        // end changed, keep start constant, modify duration
        this.logger.log('onEndChanged', $event);
        const start: moment.Moment = this.start;
        const end: moment.Moment = this.end;
        if (start.isValid() && end.isValid()) {
            const diff: number = end.diff(start);
            this.duration = moment.duration(diff);
        }
    }

    private enableDisableFormByDurationRule(newRule: string): void {
        if (newRule === 'once') {
            this.form.get('nRepetitions').disable();
            this.duration = moment.duration(this.playlist.durationSecs, 'seconds');
            this.nRepetitions = 1;
            this.onDurationChanged(undefined);
        } else if (newRule === 'multi') {
            this.form.get('nRepetitions').enable();
            this.maxMultiples = Math.floor(60 * 60 * 24 / this.playlist.durationSecs);
            this.duration = moment.duration(this.playlist.durationSecs * this.nRepetitions, 'seconds');
            this.onDurationChanged(undefined);
        } else if (newRule === 'custom') {
            this.form.get('nRepetitions').disable();
        }
        this.updateDurationSublabel();
    }

    private enableDisableFormByRepeatRule(newRule: string): void {
        if (newRule === 'daily') {
            this.dailyRules.enable();
        } else {
            this.dailyRules.disable();
        }
        if (newRule === 'weekly') {
            this.weeklyRules.enable();
        } else {
            this.weeklyRules.disable();
        }
        if (newRule === 'monthly') {
            this.monthlyRules.enable();
        } else {
            this.monthlyRules.disable();
        }
        if (newRule === 'annually') {
            this.annualRules.enable();
        } else {
            this.annualRules.disable();
        }
        if (newRule === 'never') {
            this.completionRules.disable();
        } else {
            this.completionRules.enable();
        }
        this.updateRepeatSublabel();
        // have to make sure certain other rules are enabled/disabled
        let rule: string = this.completionRulesUntil.value;
        this.enableDisableFormByCompletionRule(rule);
    }

    private enableDisableFormByCompletionRule(newRule: string): void {
        if (newRule === 'forever') {
            this.completionRulesCount.disable();
            this.completionRulesDate.disable();
        } else if (newRule === 'count') {
            this.completionRulesCount.enable();
            this.completionRulesDate.disable();
        } else if (newRule === 'date') {
            this.completionRulesCount.disable();
            this.completionRulesDate.enable();
        }
        this.updateRepeatSublabel();
    }

    private updateDurationSublabel(): void {
        const rule: string = this.durationRule;
        const h: number = this.duration.hours();
        const m: number = this.duration.minutes();
        const s: number = this.duration.seconds();
        const hstr: string = ((h < 10) ? '0' : '') + String(h);
        const mstr: string = ((m < 10) ? '0' : '') + String(m);
        const sstr: string = ((s < 10) ? '0' : '') + String(s);
        const durationString: string = hstr + ':' + mstr + ':' + sstr;
        const repetitionString: string = String(this.nRepetitions);

        if (rule === 'once') {
            this.durationSublabel = durationString + ' (once through the playlist)';
        } else if (rule === 'multi') {
            this.durationSublabel = durationString + ' (' + repetitionString + ' repetitions of the playlist)';
        } else {
            this.durationSublabel = durationString + ' (explicit)';
        }
    }

    private updateRepeatSublabel(): void {
        if (this.repeatRule === 'never') {
            this.repeatSublabel = 'Never';
        } else {
            this.repeatSublabel = this.rrule.toText();
        }
    }

    // create an RRule based on internal state of the form
    // @seealso importRRule()
    private exportRRule(): RR.RRule {
        if (this.repeatRule === 'never') {
            return undefined;
        }
        let args: any = {}; // construct RRule by building up args
        args = Object.assign(args, { dtstart: this.start.toDate() });
        if (this.repeatRule === 'daily') {
            args = this.exportRRule_RepeatDaily(args);

        } else if (this.repeatRule === 'weekly') {
            args = this.exportRRule_RepeatWeekly(args);

        } else if (this.repeatRule === 'monthly') {
            args = this.exportRRule_RepeatMonthly(args);

        } else if (this.repeatRule === 'annually') {
            args = this.exportRRule_RepeatAnnually(args);

        }
        args = this.exportRRule_Until(args);
        return new RR.RRule(args);
    }

    // set the internal state of the form based on the
    // recurrance rule (rrule)
    // @seealso exportRRule()
    private importRRule(rrule: RR.RRule): void {
        if (!rrule) {
            this.repeatRule = 'never';
            return;
        }
        switch (rrule.options.freq) {
            case RR.RRule.DAILY:
                this.importRRule_RepeatDaily(rrule);
                break;

            case RR.RRule.WEEKLY:
                this.importRRule_RepeatWeekly(rrule);
                break;

            case RR.RRule.MONTHLY:
                this.importRRule_RepeatMonthly(rrule);
                break;

            case RR.RRule.YEARLY:
                this.importRRule_RepeatAnnually(rrule);
                break;

            default:
                this.repeatRule = 'never';
                break;
        }

        this.importRRule_Until(rrule);
    }

    private importRRule_RepeatDaily(rrule: RR.RRule): void {
        this.repeatRule = 'daily';
        this.dailyRules.patchValue({
            nDays: rrule.options.interval,
        });
    }

    private exportRRule_RepeatDaily(args: any): any {
        const interval: number = this.dailyRules.get('nDays').value;
        const result: any = Object.assign(args, { freq: RR.RRule.DAILY, interval: interval });
        return result;
    }

    private importRRule_RepeatWeekly(rrule: RR.RRule): void {
        // JS uses Sunday at 0.
        // We stick to the Sunday rule.
        let onDay: boolean[] = [false, false, false, false, false, false, false];
        const byweekday: any = rrule.options.byweekday;
        if (byweekday instanceof Array) { // if we have a list of dates
            if (typeof byweekday[0] === 'number') { // and they're numbers
                byweekday.forEach(
                    (day: number) => { onDay[day] = true; },
                );
            } else {
                byweekday.forEach(
                    (day: RR.Weekday) => { onDay[day.getJsWeekday()] = true; },
                );
            }
        } else {
            if (typeof byweekday === 'number') {
                onDay[byweekday] = true;
            } else {
                onDay[byweekday.getJsWeekday()] = true;
            }
        }
        this.repeatRule = 'weekly';
        this.weeklyRules.patchValue({
            nWeeks: rrule.options.interval,
            // NOTE: RRule starts its week on Monday at 0. We use Sunday, like JS
            onSunday: onDay[6],
            onMonday: onDay[0],
            onTuesday: onDay[1],
            onWednesday: onDay[2],
            onThursday: onDay[3],
            onFriday: onDay[4],
            onSaturday: onDay[5],
        });
    }

    private exportRRule_RepeatWeekly(args: any): any {
        let byweekday: RR.Weekday[] = [];
        const map: { key: string, value: RR.Weekday }[] = [
            { key: 'onSunday', value: RR.RRule.SU },
            { key: 'onMonday', value: RR.RRule.MO },
            { key: 'onTuesday', value: RR.RRule.TU },
            { key: 'onWednesday', value: RR.RRule.WE },
            { key: 'onThursday', value: RR.RRule.TH },
            { key: 'onFriday', value: RR.RRule.FR },
            { key: 'onSaturday', value: RR.RRule.SA },
        ];
        map.forEach((item: { key: string, value: RR.Weekday }) => {
            if (this.weeklyRules.get(item.key).value) {
                byweekday.push(item.value);
            }
        });
        const interval: number = this.weeklyRules.get('nWeeks').value;
        const result: any = Object.assign(args, { freq: RR.RRule.WEEKLY, interval: interval, byweekday: byweekday });
        return result;
    }

    private importRRule_RepeatMonthly(rrule: RR.RRule): void {
        this.repeatRule = 'monthly';
        let dayOf: string;
        if (!!rrule.options.bymonthday) {
            dayOf = 'month';
        } else if (!!rrule.options.byweekday) {
            dayOf = 'week'; // general default case, including for arrays.

            // if its negative, then weekEndMonth. There are a few cases to test
            if (typeof rrule.options.byweekday === 'number') {
                if (rrule.options.byweekday < 0) {
                    dayOf = 'weekEndMonth';
                }
                // } else if ( rrule.options.byweekday instanceof RRule.Weekday)  {
                //    if (!!rrule.options.byweekday.n && rrule.options.byweekday.n < 0) {
                //        dayOf = 'weekEndMonth';
                //    }
            } else if (typeof rrule.options.byweekday === 'string') {
                if (rrule.options.byweekday[0] === '-') {
                    dayOf = 'weekEndMonth';
                }
            }
        }
        this.monthlyRules.patchValue({
            nMonths: rrule.options.interval,
            dayOf: dayOf,
        });
    }

    private exportRRule_RepeatMonthly(args: any): any {
        const interval: number = this.monthlyRules.get('nMonths').value;
        let result: any = Object.assign(args, { freq: RR.RRule.MONTHLY, interval: interval });
        const dayOf: string = this.monthlyRules.get('dayOf').value;
        if (dayOf === 'month') {
            let date: number = this.start.date();
            result = Object.assign(result, { bymonthday: date });
        } else if (dayOf === 'week') {
            const map: RR.Weekday[] = [RR.RRule.SU, RR.RRule.MO, RR.RRule.TU, RR.RRule.WE, RR.RRule.TH, RR.RRule.FR, RR.RRule.SA];
            const weekday: RR.Weekday = map[this.start.day()];
            const date: number = this.start.date(); // day of month
            const nth: number = Math.floor(date / 7) + 1; // nth weekday of month
            const byweekday: RR.Weekday = weekday.nth(nth);
            result = Object.assign(result, { byweekday: byweekday });
        } else if (dayOf === 'weekMonthEnd') {
            const map: RR.Weekday[] = [RR.RRule.SU, RR.RRule.MO, RR.RRule.TU, RR.RRule.WE, RR.RRule.TH, RR.RRule.FR, RR.RRule.SA];
            const weekday: RR.Weekday = map[this.start.day()];
            const date: number = this.start.date(); // day of month
            const daysInMonth: number = moment({ // NOTE: the zero'th day of next month == the last day of this one
                year: this.start.year(),
                month: this.start.month() + 1,
                day: 1,
            }).subtract(1, 'day').date();
            const nth: number = -(Math.floor((daysInMonth - date) / 7) + 1); // nth to last weekday of month
            const byweekday: RR.Weekday = weekday.nth(nth);
            result = Object.assign(result, { byweekday: byweekday });
        }
        return result;
    }

    // set the form according to annual
    private importRRule_RepeatAnnually(rrule: RR.RRule): void {
        this.repeatRule = 'annually';
        this.annualRules.patchValue({
            nYears: rrule.options.interval,
        });
    }

    private exportRRule_RepeatAnnually(args: any): any {
        const interval: number = this.annualRules.get('nYears').value;
        const result: any = Object.assign(args, { freq: RR.RRule.YEARLY, interval: interval });
        return result;
    }

    // set this.completionRules based on rrule
    private importRRule_Until(rrule: RR.RRule): void {

        if (!!rrule.options.until) {
            const dateStr: string = moment(rrule.options.until).format('YYYY-MM-DD[T]HH:mm:ss');
            this.completionRules.patchValue({
                until: 'date',
                date: dateStr,
            });

        } else if (!!rrule.options.count) {
            this.completionRules.patchValue({
                until: 'count',
                count: rrule.options.count,
            });
        }
    }

    private exportRRule_Until(args: any): any {
        const until: string = this.completionRules.get('until').value;
        let result: any = Object.assign(args);
        if (until === 'date') {
            const closedate: Date = moment(this.completionRulesDate.value).toDate();
            result = Object.assign(result, { until: closedate });
        } else if (until === 'count') {
            const count: number = this.completionRulesCount.value;
            result = Object.assign(result, { count: count });
        }
        return result;
    }
}
