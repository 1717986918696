import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import {
    TdDataTableComponent,
    TdDataTableService,
    TdDataTableSortingOrder,
    ITdDataTableSortChangeEvent,
    ITdDataTableColumn,
    TdDialogService,
    IPageChangeEvent,
} from '@covalent/core';

import { NGXLogger } from 'ngx-logger';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { L10nService } from '../l10n/l10n.service';
import { IUser } from '../models/user.model';
import { IDataArray } from '../models/data-array.model';
import { UserService } from '../services';
import { PasswordDialogService } from '../dialogs/password-dialog.service';

@Component({
    selector: 'gk-user-list',
    templateUrl: './user.list.component.html',
    styleUrls: ['./user.list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {

    private subscription: any;

    @Input() hasAdminRole: boolean;

    @ViewChild(TdDataTableComponent) dataTable: TdDataTableComponent;

    data: IUser[] = [];

    columns: ITdDataTableColumn[] = [
        { name: 'name', label: 'Name' },
        // { name: 'description', label: 'Description' },
        { name: 'isActive', label: 'Status' },
        { name: 'id', label: '' },
    ];

    lookup: Object = {
        name: 'Users.ColHeader.Name',
        description: 'Users.ColHeader.Description',
        isActive: 'Users.ColHeader.Status',
    };

    filteredData: IUser[] = this.data;
    filteredTotal: number = this.data.length;

    selectedRows: IUser[] = [];

    searchTerm: string = '';
    fromRow: number = 1;
    currentPage: number = 1;
    pageSize: number = 50;
    sortBy: string = 'name';
    sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    selectedIndexIntoTabGroup: number = 0;

    constructor(private _dataTableService: TdDataTableService,
        private _dialogService: TdDialogService,
        private _userService: UserService,
        private _snackBarService: MatSnackBar,
        private translate: TranslateService,
        private logger: NGXLogger,
        private _passwordService: PasswordDialogService,
    ) { }

    ngOnInit(): void {
        this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.retranslateColumnHeaders();
        });
        this.retranslateColumnHeaders();
        this.filter();
        this.loadUsers();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAccountChangeEvent(event: any): void {
        this.logger.log('user.list: onAccountChangeEvent()');
        this.data = [];
        this.loadUsers();
    }

    onSortEvent(sortEvent: ITdDataTableSortChangeEvent): void {
        this.sortBy = sortEvent.name;
        this.sortOrder = sortEvent.order;
        this.filter();
    }

    onSearchTermEvent(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.filter();
    }

    onPageChangeEvent(pagingEvent: IPageChangeEvent): void {
        this.fromRow = pagingEvent.fromRow;
        this.currentPage = pagingEvent.page;
        this.pageSize = pagingEvent.pageSize;
        this.filter();
    }

    filter(): void {
        let newData: any[] = this.data;
        newData = this._dataTableService.filterData(newData, this.searchTerm, true);
        this.filteredTotal = newData.length;
        newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
        newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
        this.filteredData = newData;
    }

    loadUsers(): void {
        this._userService.getAll().subscribe(
            (dataArray: IDataArray<IUser>) => {
                this.data = dataArray.data;
            },
            (err: any) => {
                this.logger.error(err);
            },
            () => {
                this.logger.log('loaded users', this.data);
                this.filter();
            },
        );
    }

    reload(): void {
        this.data = [];
        this.filter();
        this.loadUsers();
    }

    onDeleteUserEvent(id: string): void {

        let userName: string = this.data.find((x: IUser) => x.id === id).name;

        this._dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to delete ' + userName + '?' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this._userService.delete(id).subscribe(
                        (data: void) => {
                            this.data = this.data.filter((user: IUser) => {
                                return user.id !== id;
                            });
                            this.filteredData = this.filteredData.filter((user: IUser) => {
                                return user.id !== id;
                            });
                            this._snackBarService.open('User deleted', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this._dialogService.openAlert({ message: 'There was an error=' + error });
                        },
                    );
                }
            });
    }

    changePassword(id: string): void {
        this._passwordService.confirm('confirm dialog', 'are you sure?')
            .subscribe((newPassword: string) => {
                this._userService.changePassword(id, newPassword).subscribe(
                    () => {
                        this._snackBarService.open('Password changed.', 'Ok', { duration: 3000 });
                    },
                    (err: any) => {
                        this._snackBarService.open('Error: Change password failed.', 'Ok', { duration: 3000 });
                    },
                );
            });
    }

    retranslateColumnHeaders(): void {
        this.columns.forEach(async (col: ITdDataTableColumn) => {
            if (col.label) {
                col.label = await this.translate.get(this.lookup[col.name]).toPromise();
            }
        });
        this.dataTable.refresh();
    }

}
