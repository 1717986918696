import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

import { AuthService } from './auth.service';
import { IAccount } from '../models/account.model';
import { IUser } from '../models/user.model';

export class ProfileSingleton {

    private static _instance: ProfileSingleton;

    private _effectiveAccountID: string = undefined;

    username: string = undefined;

    email: string = undefined;

    userID: string = undefined;

    accountID: string = undefined;

    hasAdminRole: boolean = false;

    hasSubaccountRole: boolean = false;

    hasAuditorRole: boolean = false;

    availableAccounts: any[] = [];

    private constructor() { }

    public static instance(): ProfileSingleton {
        if (!ProfileSingleton._instance) {
            ProfileSingleton._instance = new ProfileSingleton();
        }
        return ProfileSingleton._instance;
    }

    public static reset(): void {
        ProfileSingleton._instance = new ProfileSingleton();
    }

    public get effectiveAccountID(): string {
        if (!this._effectiveAccountID) {
            this._effectiveAccountID = localStorage.getItem('ProfileService.effectiveAccountID');
        }
        return this._effectiveAccountID;
    }

    public set effectiveAccountID(id: string) {
        this._effectiveAccountID = id;
        localStorage.setItem('ProfileService.effectiveAccountID', id);
    }

    public get hasDeusModus(): boolean {
        let deusFolks: string[] = ['admin@addulate.com', 'gary@addulate.com', 'mark@addulate.com', 'scottmillsgray@gmail.com'];
        return deusFolks.includes(this.email);
    }
}

interface IProfile {
    self: IUser;
    accounts: IAccount[];
}

@Injectable()
export class ProfileService {

    // private helper methods
    private get headers(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private logger: NGXLogger,
    ) {
    }

    public static profileSingleton(): ProfileSingleton {
        return ProfileSingleton.instance();
    }

    getProfile(): Observable<ProfileSingleton> {
        let url: string = environment.apiEndpoint + '/profile';

        return this.http.get<IProfile>(url, { headers: this.headers })
            .pipe(
                map((value: IProfile) => { return this.handleProfileResponse(value); }),
                catchError((err: any) => { return this.handleProfileError(err); }),
            );
    }

    addProfileHttpHeaders(headers: HttpHeaders): HttpHeaders {
        let h: HttpHeaders = this.auth.addAuthHttpHeaders(headers);
        if (ProfileSingleton.instance().effectiveAccountID) {
            h = h.set('X-Addulate-Account', ProfileSingleton.instance().effectiveAccountID);
        }
        return h;
    }

    private handleProfileResponse(body: IProfile): ProfileSingleton {
        if (body.self.name) {
            this.logger.log('body.self = ', body.self);
            ProfileSingleton.instance().username = body.self.name;
            ProfileSingleton.instance().email = body.self.email;
            ProfileSingleton.instance().userID = body.self.id;
            ProfileSingleton.instance().accountID = body.self.accountId;
            ProfileSingleton.instance().hasAdminRole = body.self.hasAdminRole;
            ProfileSingleton.instance().hasSubaccountRole = body.self.hasSubaccountRole;
            ProfileSingleton.instance().hasAuditorRole = body.self.hasAuditorRole;
            if (body.self.effectiveAccountID) {
                ProfileSingleton.instance().effectiveAccountID = body.self.effectiveAccountID;
            } else if (!ProfileSingleton.instance().effectiveAccountID) {
                ProfileSingleton.instance().effectiveAccountID = body.self.accountId;
            }
            ProfileSingleton.instance().availableAccounts = body.accounts;
            return ProfileSingleton.instance();
        } else {
            ProfileSingleton.reset();
            this.auth.logout();
            return undefined;
        }
    }

    private handleProfileError(error: any): Observable<any> {
        ProfileSingleton.reset();
        this.auth.logout();
        let errMsg: string = error.toString();
        this.logger.error(errMsg);
        return Observable.throw(errMsg);
    }

}
