import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { ImageListComponent } from '../lists/image.list.component';
import { VideoListComponent } from '../lists/video.list.component';
import { L10nService } from '../l10n/l10n.service';

@Component({
    selector: 'gk-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.css'],
    providers: [L10nService],
})
export class MediaComponent {

    // NOTE: selectedIndex is cached so user can return to previous tab
    private _selectedIndex: number = -1;

    get selectedIndex(): number {
        if (this._selectedIndex === -1) {
            this._selectedIndex = Number(sessionStorage.getItem('MediaComponent.selectedIndex'));
        }
        return this._selectedIndex;
    }

    set selectedIndex(n: number) {
        this._selectedIndex = n;
        sessionStorage.setItem('MediaComponent.selectedIndex', String(n));
    }

    @ViewChild(ImageListComponent)
    private imageList: ImageListComponent;

    @ViewChild(VideoListComponent)
    private videoList: VideoListComponent;

    @Input()
    isEditable: boolean = true;

    constructor(
        private _snackBarService: MatSnackBar,
        private logger: NGXLogger,
    ) { }

    onAccountChangeEvent(event: any): void {
        this.logger.log('media.component: reloading images and videos');
        this.imageList.onAccountChangeEvent(event);
        this.videoList.onAccountChangeEvent(event);
    }

    linkForActiveTab(): string {
        this.logger.log('switching to ', this.selectedIndex);
        switch (this.selectedIndex) {
            case 0:
                return 'images/';
            case 1:
                return 'videos/';
            case 2:
                return 'hashtags/';
            default:
                return '';
        }
    }
}
