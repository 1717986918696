import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'gk-repeat-annually',
    templateUrl: './repeat-annually.component.html',
    styleUrls: ['./repeat-annually.component.scss'],
})
export class RepeatAnnuallyComponent {

    @Input() parent: FormGroup;

    get nYears(): AbstractControl {
        return this.parent.get('nYears');
    }
}
