import { Injectable, Component, OnInit, ViewChild, AfterViewInit, ViewContainerRef } from '@angular/core';

import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';

import { Observable } from 'rxjs';

import { TdMediaService, TdLoadingService, LoadingType, LoadingMode, TdDialogService } from '@covalent/core';

import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment-timezone';

import { BaseEditor } from '../../base/base-editor';
import { IScreen } from '../../models/screen.model';
import { ILocation } from '../../models/location.model';
import { IRemoteReference } from '../../models/data-array.model';
import { ScreenService, LocationService } from '../../services';
import { L10nService } from '../../l10n/l10n.service';
import { LocationEditorComponent } from '../../location/location.editor.component';
import { LeaveWithoutSaveDialogComponent } from '../../dialogs/leave-without-save-dialog.component';
import { PairingService } from '../../services/pairing.service';
import { PairingDialogComponent } from '../../dialogs/pairing-dialog/pairing-dialog.component';
import { DebugFormComponent } from '../../debug/debug-form/debug-form.component';

@Injectable()
export class CanDeactivateScreenEditor implements CanDeactivate<ScreenEditorComponent> {
    constructor(private dialog: MatDialog) { }

    canDeactivate(
        component: ScreenEditorComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (component.dirty) {
            let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> = this.dialog.open(LeaveWithoutSaveDialogComponent);
            return dialogRef.afterClosed();
        } else {
            return true;
        }
    }
}

@Component({
    selector: 'gk-screen-editor',
    templateUrl: './screen.editor.component.html',
    styleUrls: ['./screen.editor.component.css'],
    viewProviders: [ScreenService, LocationService],
    providers: [L10nService],
})

export class ScreenEditorComponent extends BaseEditor implements OnInit, AfterViewInit {

    @ViewChild(LocationEditorComponent)
    locationEditor: LocationEditorComponent;

    @ViewChild('myForm') myForm: NgForm;

    @ViewChild(DebugFormComponent)
    debugView: DebugFormComponent;

    screenId: string;
    name: string;
    description: string;

    screen: IScreen;
    location: ILocation;

    action: string;
    cardTitle: string;
    channelId: string;

    timezone: string;
    timezones: string[] = moment.tz.names();
    filteredTimezones: string[];
    localtime: string;
    hardwareId: string;

    deviceModel: string;
    serialNumber: string;
    diagonalInches: number;
    elevationFeet: number;
    heightPixels: number;
    aspectRatio: string;
    orientation: string;
    estAudienceCoverage: number;
    countAudienceStart: number;
    countAudienceStartStr: string; // seconds encoded as HH:MM:SS
    countAudienceDuration: number;
    countAudienceDurationStr: string; // seconds encoded as HH:MM:SS
    countAudiencePeriod: number;

    password: string;

    readonly allowedDeviceGroups: object[] = [
        {
            name: 'set-top boxes',
            disabled: false,
            devices: [
                {
                    label: 'MiBox S',
                    value: 'MiBox 4',
                    disabled: false,
                },
                {
                    label: 'NVIDIA Shield',
                    value: 'NVIDIA Shield',
                    disabled: false,
                },
                {
                    label: 'H96',
                    value: 'H96',
                    disabled: false,
                },
                {
                    label: 'X96-mini',
                    value: 'X96-mini',
                    disabled: false,
                },
                {
                    label: 'X96',
                    value: 'X96',
                    disabled: false,
                },
                {
                    label: 'other',
                    value: 'other',
                    disabled: false,
                },
            ],
        },
        // {
        //     name: 'all-in-one',
        //     disabled: true,
        // },
    ];

    readonly allowedAspectRatios: object[] = [
        {
            label: '16:9 (HD)',
            value: '16:9',
            disabled: false,
        },
        {
            label: '21:9 (ultra wide)',
            value: '21:9',
            disabled: false,
        },
        {
            label: '4:3 (tablets)',
            value: '4:3',
            disabled: false,
        },
    ];

    readonly allowedResolutions: object[] = [
        {
            label: '1080p (HD)',
            value: 1080,
            disabled: false,
        },
        {
            label: '2160p (4K)',
            value: 2160,
            disabled: false,
        },
        {
            label: '720p',
            value: 720,
            disabled: false,
        },
        {
            label: 'custom',
            value: -1,
            disabled: true,
        },
    ];

    get dirty(): boolean {
        return this.myForm.dirty || this.locationEditor.dirty;
    }

    get valid(): boolean {
        return this.myForm.form.valid && this.locationEditor.valid;
    }

    get needsPairing(): boolean {
        return !this.hardwareId;
    }

    get isPaired(): boolean {
        return !!this.hardwareId;
    }

    constructor(
        private screenService: ScreenService,
        private locationService: LocationService,
        private pairingService: PairingService,
        private route: ActivatedRoute,
        private vcr: ViewContainerRef,
        public media: TdMediaService,
        private dialogService: TdDialogService,
        protected router: Router,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected loadingService: TdLoadingService,
        protected logger: NGXLogger,
    ) {
        super(router, dialog, snackBar, loadingService, logger);
        this.backPath = ['/screens'];
    }

    ngOnInit(): void {
        this.route.url.subscribe((url: any) => {
            this.logger.log('ScreenEditorComponent.ngOnInit: url=', url);
            this.action = (url.length > 1 ? url[1].path : 'add');
            if (this.action === 'add') {
                this.cardTitle = 'Screens.ScreenEditor.CardTitle.Create';
            } else {
                this.loadData();
                this.cardTitle = 'Screens.ScreenEditor.CardTitle.Modify';
            }
        },
            (err: any) => {
                this.logger.warn('MediaVideoEditor.onInit()', err);
                this.snackBar.open('Routing Error', 'Ok');
            });
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
        // wait a tick first to avoid one-time devMode unidirectional-data-flow-violation error
        // see https://angular.io/guide/component-interaction#parent-calls-an-viewchild
        // setTimeout(() => this.debugView.formControl = this.userForm);
        // setTimeout(() => this.debugView.formControl = this.myForm.control);
    }

    pairingClick(): void {
        let screen: IScreen = this.screenFromStateData();
        let dialogRef: MatDialogRef<PairingDialogComponent, string> = this.dialog.open(PairingDialogComponent, {
            width: '500px',
            disableClose: true,
            data: {
                screen: screen,
                pairingService: this.pairingService,
            },
        });

        dialogRef.afterClosed().subscribe((hardwareId: string) => { // undefined implies the device hasn't paired
            // console.log('The dialog was closed, hardwareId=', hardwareId);
        });

    }

    unpairingClick(): void {
        this.dialogService
            .openConfirm({ title: 'Confirmation Required', message: 'Are you sure you want to unpair this screen' })
            .afterClosed().subscribe((confirm: boolean) => {
                if (confirm) {
                    this.pairingService.unpairDevice(this.screenId, this.hardwareId).subscribe(
                        () => {
                            this.snackBar.open('Pairing removed', 'Ok', { duration: 3000 });
                        },
                        (error: Error) => {
                            this.showError(error);
                        },
                    );
                    this.hardwareId = undefined;
                }
            });
    }

    saveScreenClick(): void {
        // NOTE. First we need to
        // 1. save the location,
        // 2. node the locationId
        // 3. save the screen.
        this.loadingService.register(this.SPINNER_NAME);
        this.locationEditor.save().subscribe(
            (location: ILocation) => {
                this.location = location;
            },
            (err: any) => {
                this.logger.error(err);
                this.showError(err);
            },
            () => {
                this.saveScreen();
            },
        );
    }

    saveScreen(): void {
        this.myForm.control.markAsPending();
        this.locationEditor.markAsPending();
        let screen: IScreen = this.screenFromStateData();

        if (this.action === 'add') {
            this.screenService.create(screen).subscribe(
                (data: HttpResponse<IRemoteReference<IScreen>>) => {
                    this.myForm.control.markAsPristine();
                    this.goBack();
                },
                (err: any) => {
                    this.logger.error(err);
                    this.showError(err);
                },
            );
        } else {
            this.screenService.update(screen).subscribe(
                (data: void) => {
                    this.myForm.control.markAsPristine();
                    this.goBack();
                },
                (err: any) => {
                    this.logger.error(err);
                    this.showError(err);
                },
                () => {
                    this.goBack();
                },
            );
        }
    }

    filter(): void {
        this.filteredTimezones = this.timezones.filter((option: string) => {
            if (this.timezone) {
                return option.toLocaleLowerCase().indexOf(this.timezone.toLocaleLowerCase()) !== -1;
            } else {
                return [];
            }
        });
        const index: number = this.timezones.findIndex((val: string, idx: number, arry: string[]) => {
            return val === this.timezone;
        });
        if (index !== -1) {
            this.localtime = moment.tz(Date.now(), this.timezone).format('Z z');
        }
    }

    onKeyAudienceStart(input: string): void {
        this.countAudienceStartStr = this.HHMMSSreformat(input);
        this.countAudienceStart = this.HHMMSS2secs(this.countAudienceStartStr);
    }

    onKeyAudienceDuration(input: string): void {
        this.countAudienceDurationStr = this.HHMMSSreformat(input);
        this.countAudienceDuration = this.HHMMSS2secs(this.countAudienceDurationStr);
    }

    private loadData(): void {
        this.route.params.subscribe((params: { id: string }) => {
            this.logger.log('ScreenEditorComponent.ngOnInit: params=', params);
            let screenID: string = params.id;
            this.screenService.getById(screenID).subscribe((screen: IScreen) => {
                this.logger.log('service returned=', screen);
                this.screenId = screen.id;
                this.hardwareId = screen.hardwareId;
                this.name = screen.name;
                this.description = screen.description;

                this.deviceModel = (!!screen.deviceModel) ? screen.deviceModel : '';
                this.serialNumber = (!!screen.serialNumber) ? screen.serialNumber : '';
                this.diagonalInches = (!!screen.diagonalInches) ? screen.diagonalInches : 0;
                this.aspectRatio = (!!screen.aspectRatio) ? screen.aspectRatio : '';
                this.heightPixels = (!!screen.heightPixels) ? screen.heightPixels : 0;
                this.orientation = (!!screen.orientation) ? screen.orientation : 'landscape';
                this.elevationFeet = (!!screen.elevationFeet) ? screen.elevationFeet : 0;
                this.estAudienceCoverage = (!!screen.estAudienceCoverage) ? screen.estAudienceCoverage * 100.0 : 0;
                this.countAudienceStart = (!!screen.countAudienceStart) ? screen.countAudienceStart : 0;
                this.countAudienceStartStr = this.secs2HHMMSS(this.countAudienceStart);
                this.countAudienceDuration = (!!screen.countAudienceDuration) ? screen.countAudienceDuration : 0;
                this.countAudienceDurationStr = this.secs2HHMMSS(this.countAudienceDuration);
                this.countAudiencePeriod = (!!screen.countAudiencePeriod) ? ((screen.countAudiencePeriod / 60) % 60) : 0;
                this.timezone = screen.timezone;

                if (screen.location) {
                    this.location = screen.location;
                    this.locationEditor.setLocation(this.location);
                }
                this.filter();
            });
        });
    }


    private screenFromStateData(): IScreen {
        let screen: IScreen = <IScreen>{
            id: this.screenId,
            name: this.name,
            description: this.description,
            locationId: this.location.id,
            // ILocation: this.location,
            channelId: this.channelId,
            timezone: this.timezone,
            hardwareId: this.hardwareId,
            password: this.password,
            deviceModel: this.deviceModel,
            serialNumber: this.serialNumber,
            diagonalInches: this.diagonalInches,
            aspectRatio: this.aspectRatio,
            orientation: this.orientation,
            elevationFeet: this.elevationFeet,
            heightPixels: this.heightPixels,
            estAudienceCoverage: !this.estAudienceCoverage ? 0 : this.estAudienceCoverage / 100.0,
            countAudienceStart: this.countAudienceStart,
            countAudienceDuration: this.countAudienceDuration,
            countAudiencePeriod: this.countAudiencePeriod * 60,
        };
        return screen;
    }

    private HHMMSSreformat(hhmmss: string): string {
        let raw: string = hhmmss.replace(/[^0-9a-fA-F]/gi, '');
        if (!parseInt(raw, 10)) {
            raw = '0';
        }
        if (raw.length > 4) { // take rightmost 4
            raw = raw.substring(raw.length - 4, raw.length);
        }
        raw = raw.padStart(4, '0');
        return raw.substring(0, 2) + ':' + raw.substring(2, 4);
    }

    private HHMMSS2secs(hhmmss: string): number {
        const vals: string[] = hhmmss.split(':').reverse();
        let multiplier: number = 60;
        let secs: number = 0;
        vals.forEach((val: string) => {
            secs += parseInt(val, 10) * multiplier;
            multiplier *= 60;
        });
        return secs;
    }

    private secs2HHMMSS(secs: number): string {
        const ss: number = secs % 60;
        const mm: number = (secs / 60) % 60;
        const hh: number = Math.floor(secs / (60 * 60));
        return String(hh).padStart(2, '0') + ':' +
            String(mm).padStart(2, '0');
    }



}
