// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment: any = {
    name: 'local',
    production: false,
    apiEndpoint: "https://addulate-api.yatilabs.com:3000",
    androidApiEndpoint: 'http://10.0.2.2:3000',
    iotEndpoints: {
        // 'Asia/Tokyo': 'a1yg5dc4qcx5ex.iot.ap-northeast-1.amazonaws.com',
        'default': 'a1yg5dc4qcx5ex-ats.iot.us-west-2.amazonaws.com',
    },
    screenUrl: "https://addulate-screen-js.yatilabs.com/",
    androidScreenUrl: "localhost:4100",
    handshakeEndpoint: 'https://api2.addulate.com/v2',
    cognitoRegion: 'us-west-2',
    cognitoUserPoolId: 'us-west-1_DVKJU5IcP',
    cognitoAppClientId: "5911q4gac4qk7p6nq6lcn792ah",
    cognitoDeusModusIdentityPoolId: 'us-west-2:29f7ae83-a6d7-4d34-bebe-b132c6fdc123',
    s3BucketName: "aduulate",
    s3IdentityPoolId: "us-west-1:d78db170-6715-47a8-a67e-9f0c833b4f36",
    mixpanel_token: '123abc-prod',
    releaseInfo: "VersionInfo",
    s3BucketRegion: 'us-west-1',
    googleKey: 'AIzaSyDqHpprl75YRoQPmQ0c6GYPYf2fuFfXg3k', // TestProject for garyaddulate@gmail.com
};

// export const environment = {
//     production: false,
//     apiEndpoint: "http://52.52.124.106:3000",
//     screenUr: "http://52.52.124.106:4300",
//     androidScreenUrl: "http://52.52.124.106:4300",
//     cognitoAppClientId: "arn:aws:cognito-idp:us-west-1:684460736423:userpool/us-west",
//     s3BucketName: "addulate",
//     s3IdentityPoolId: "us-west-1:d78db170-6715-47a8-a67e-9f0c833b4f36",
//     mixpanel_token: '123abc-prod',
//     releaseInfo: VersionInfo
// };