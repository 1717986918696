import {
  Injectable,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  ActivatedRoute,
  Router,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { NgForm, NgModel } from "@angular/forms";

import { MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";

import { Observable } from "rxjs";

import {
  TdMediaService,
  StepState,
  IStepChangeEvent,
  TdLoadingService,
  LoadingType,
  LoadingMode,
  TdBytesPipe,
} from "@covalent/core";

import { NGXLogger } from "ngx-logger";

import { BaseEditor } from "../../base/base-editor";
import { FileUploadInfo } from "./file-upload-info";
import { IImage, ImageUtils } from "../../models/image.model";
import { IRemoteReference } from "../../models/data-array.model";
import { ImageService, AwsS3Service } from "../../services";
import { L10nService } from "../../l10n/l10n.service";
import { LeaveWithoutSaveDialogComponent } from "../../dialogs/leave-without-save-dialog.component";

import { ProfileService, ProfileSingleton } from "../../auth/profile.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class CanDeactivateMediaImageEditor
  implements CanDeactivate<MediaImageEditorComponent>
{
  constructor(private dialog: MatDialog) {}

  canDeactivate(
    component: MediaImageEditorComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.dirty) {
      let dialogRef: MatDialogRef<LeaveWithoutSaveDialogComponent> =
        this.dialog.open(LeaveWithoutSaveDialogComponent);
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }
}

@Component({
  selector: "gk-media-image-editor",
  templateUrl: "./media-image-editor.component.html",
  styleUrls: ["./media-image-editor.component.scss"],
})
export class MediaImageEditorComponent
  extends BaseEditor
  implements OnInit, AfterViewInit
{
  @ViewChild("imgElement") imgElement: ElementRef;
  @ViewChild("myForm") myForm: NgForm;
  @ViewChild("imageTitleControl") imageTitleControl: NgModel;

  // Determines if component is creating a new image, or editing existing one
  //  == 'add' for new images
  //  == image.id for editing existing images.
  //  @see ngOnInit()
  action: string;
  cardTitle: string;

  //
  // image model
  //
  image: IImage;

  //
  // Form data, matches attributes of a image model
  //
  id: string; // id of the image object
  title: string; // title of the
  description: string;
  thumbnailUrl: string;
  imageUrl: string;
  renditions: any;
  width: number;
  height: number;
  durationSecs: number;
  bitrateMbps: number;
  fileSizeBytes: number;
  mimeType: string;
  aspectRatio: string;

  //
  // Relating to LoadImage Panel
  //
  imageUpload: FileUploadInfo = new FileUploadInfo();

  //
  // BaseEditor overrides
  //

  // true iff the editor has changed anything that needs saving
  get dirty(): boolean {
    return this.myForm.dirty || this.imageUpload.dirty;
  }

  // true iff the editor is in a valid state (pristine or dirty)
  get valid(): boolean {
    return this.myForm.valid && !!this.imageUpload.data;
  }

  constructor(
    private imageService: ImageService,
    private route: ActivatedRoute,
    public media: TdMediaService,
    private s3: AwsS3Service,
    private bytesPipe: TdBytesPipe,
    protected router: Router,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected loadingService: TdLoadingService,
    protected logger: NGXLogger
  ) {
    super(router, dialog, snackBar, loadingService, logger);
    this.backPath = ["/media"];
  }

  //
  // establishes this.action, which remains constant for duration of view
  // Also loads image data for form (if appropriate).
  //
  ngOnInit(): void {
    this.route.url.subscribe(
      (url: any) => {
        this.logger.log("imageFormComponent.ngOnInit: url=", url);
        this.action = url.length > 1 ? url[1].path : "add";
        if (this.action === "add") {
          this.cardTitle = "Media.ImageEditor.CardTitle.Create";
        } else {
          this.loadData();
          this.cardTitle = "Media.ImageEditor.CardTitle.Modify";
        }
      },
      (err: any) => {
        this.logger.warn("MediaImageEditor.onInit()", err);
        this.snackBar.open("Routing Error", "Ok");
      }
    );
  }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    this.media.broadcast();
    this.imgElement.nativeElement.onload = (event: any) => {
      this.width = event.srcElement.naturalWidth;
      this.height = event.srcElement.naturalHeight;
    };
  }

  //
  // These are the two main workflows
  //
  get isNewImageWorkflow(): boolean {
    return this.action === "add";
  }

  get isEditImageWorkflow(): boolean {
    return this.action.indexOf("image_") === 0;
  }

  //
  // These getters are used in ngIf's to show/hide div.
  //
  get showLoadImagePanel(): boolean {
    return this.isNewImageWorkflow && !this.imageUrl;
  }

  get showReviewImagePanel(): boolean {
    return !this.showLoadImagePanel;
  }

  get showEditimageDetailsPanel(): boolean {
    return true;
  }

  //
  // callbacks from loadImagePanel
  //
  onSelectImageFileEvent(file: File): void {
    this.imageUpload.selectFile(
      file,
      "Media.ImageEditor.Step1.UploadMsg.2Upload"
    );
    if (!this.myForm.touched) {
      let terms: string[] = file.name.split(".");
      terms.pop();
      this.title = terms.join(".").replace("_", " ");
      this.imageTitleControl.control.setValue(this.title);
      this.imageTitleControl.control.markAsTouched();
      this.imageTitleControl.control.updateValueAndValidity();
    }
    this.logger.log(file);
  }

  onUploadImageFileEvent(file: File): void {
    this.imageUpload.statusMessage =
      "Media.ImageEditor.Step1.UploadMsg.3Processing";
    // const self: this = this;
    const ingestBucket: string = environment.s3BucketName;
    const ingestPath: string = [
      ProfileService.profileSingleton().accountID,
      ProfileService.profileSingleton().userID,
      this.s3.safeKeyName(file.name),
    ].join("/");
    this.logger.warn("s3BucketName", environment.s3BucketName);
    this.logger.warn("s3IdentityPoolId", environment.s3IdentityPoolId);

    this.imageUpload.managedUpload = this.s3.uploadFile(
      file,
      ingestBucket,
      ingestPath
    );
    this.imageUpload.managedUpload
      .on("httpUploadProgress", (evt: any) => {
        this.imageUpload.progress(
          evt.loaded,
          evt.total,
          this.bytesPipe,
          "Media.ImageEditor.Step1.UploadMsg.4UploadedNBytes"
        );
      })
      .send((err: any, data: any) => {
        if (err) {
          this.logger.warn("Storage.uploadFile(video)", err);
          this.imageUpload.error(err);
          this.showError(err);
        } else {
          this.imageUpload.complete(
            data,
            "Media.VideoEditor.Step1.UploadMsg.5Complete"
          );
          console.log("data", data);
          this.imageUrl = data.Location;
          this.thumbnailUrl = data.Location;
          this.mimeType = file.type;
          this.fileSizeBytes = file.size;

          this.logger.log("Upload success");
        }
        this.imageUpload.fractionUploaded = 0;
        this.logger.log("upload complete");
      });
  }

  onLoadedMetadataEvent(data: any): void {
    this.logger.log(data);
  }

  onUserClickedSave(): void {
    this.logger.log("title=", this.title);
    // this.isSaving = true;
    this.myForm.control.markAsPending();
    this.showSpinner();
    console.log("save url",this.imageUrl)
    console.log(this.action)
    let imgUrl = this.thumbnailUrl;


    let image: IImage = <IImage>{
      id: this.id,
      title: this.title,
      description: this.description,
      thumbnailUrl: imgUrl, // ingest location
      imageUrl: imgUrl,
      width: this.imgElement.nativeElement
        ? this.imgElement.nativeElement.naturalWidth
        : this.width,
      height: this.imgElement.nativeElement
        ? this.imgElement.nativeElement.naturalHeight
        : this.height,
      fileSizeBytes: this.fileSizeBytes,
      mimeType: this.mimeType,
    };
    if (this.action === "add") {
      console.log(image)
      this.imageService.create(image).subscribe(
        (imageRef: IRemoteReference<IImage>) => {
          this.id = imageRef.id;
          this.finalizeCreate(imageRef);
          this.loadingService.resolve(this.SPINNER_NAME);

        },
        (err: any) => {
          this.logger.warn("ImageService.create()", err);
          this.myForm.control.markAsDirty();
          this.showError(err);
        }
      );
    } else {
      console.log("in put")
      this.imageService.update(image).subscribe(
        (data: void) => {
          this.myForm.control.markAsPristine();
          this.goBack();
        },
        (err: any) => {
          this.logger.warn("ImageService.create()", err);
          this.myForm.control.markAsDirty();
          this.showError(err);
        }
      );
    }
  }

  onUploadCancelledEvent(): void {
    this.imageUpload.abort();
  }

  onUserClickedCancel(): void {
    this.goBack();
  }

  /*
   * After a new record is created, we need to copy image file
   * over using image_ID recieved from REST API. Same with the image.
   */
  private finalizeCreate(newImageRef: IRemoteReference<IImage>): void {
    this.imageUpload.statusMessage = "Media.ImageEditor.Finalize.1Commiting";
    const self: this = this;
    const imageTargetKey: string =
      ProfileService.profileSingleton().effectiveAccountID +
      "/" +
      this.id +
      this.imageUpload.extension;

    this.s3
      .copyObject(
        this.imageUpload.data.Bucket,
        this.imageUpload.data.Key,
        this.imageUpload.data.Bucket,
        imageTargetKey
      )
      .on("success", (data: any) => {
        this.imageUpload.statusMessage = "Media.ImageEditor.Finalize.2Updating";
        // let bucketURL: string =
        //   data.request.httpRequest.endpoint.href +
        //   data.request.httpRequest.path;
        // let cloudfrontURL: string =
        //   data.request.httpRequest.endpoint.protocol +
        //   "/" +
        //   data.request.httpRequest.path;
        // this.imageUrl = cloudfrontURL;
        // console.log(data)
      })
      .on("error", (err: any) => {
        this.logger.warn("StorageService.copy(video)", err);
        self.imageUpload.statusMessage = "Error: " + err;
        this.showError(err);
      })
      .on("complete", (response: any) => {
        self.checkReturn();
      })
      .send();
  }

  private loadData(): void {
    this.route.params.subscribe((params: { id: string }) => {
      this.logger.log("imageFormComponent.ngOnInit: params=", params);
      let imageID: string = params.id;
      this.imageService.getById(imageID).subscribe((image: IImage) => {
        this.logger.log("service returned=", image);
        this.image = image;
        this.id = image.id;
        this.title = image.title;
        this.description = image.description;
        this.thumbnailUrl = image.thumbnailUrl;
        this.imageUrl = image.imageUrl;
        this.mimeType = image.mimeType;
        this.width = image.width;
        this.height = image.height;
        this.fileSizeBytes = image.fileSizeBytes;
        this.aspectRatio = ImageUtils.aspectRatio(image);
        this.myForm.control.markAsPristine();
        this.myForm.control.markAsUntouched();
      });
    });
  }

  private checkReturn(): void {
    let image: IImage = <IImage>{
      id: this.id,
      title: this.title,
      description: this.description,
      thumbnailUrl: this.imageUrl,
      imageUrl: this.imageUrl,
      width: this.width,
      height: this.height,
      fileSizeBytes: this.fileSizeBytes,
      mimeType: this.mimeType,
    };
    this.imageService.update(image).subscribe(
      (data: void) => {
        this.myForm.control.markAsPristine();
        this.imageUpload.dirty = false;
        this.myForm.reset({
          title: this.title,
          description: this.description,
        });
        this.goBack();
      },
      (err: any) => {
        this.logger.warn("finalizing image metadata", err);
        this.showError(err);
      }
    );
  }
}
