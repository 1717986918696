import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSlideToggle, MatSlideToggleChange } from '@angular/material';

@Component({
    selector: 'gk-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss'],
})
export class PreviewDialogComponent {
    url: string;
    width: number = 600;
    height: number = 450;
    url1="http://localhost:4100/#/playlists/playlist_3f3be075-5384-4098-a078-dd4e89f66a6a?t=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjoidXNlciIsImlkIjoidXNlcl8wMDAwMDAwMC0wMDAwLTQwMDAtYjAwMC0wMDAwMDAwMDAwMDAifQ.IvRKfAjhXQpMoQMc_cOuVMhNyIZGMzqYbZnZ5jRFuMg&a=account_00000000-0000-4000-b000-000000000000"

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (data) {
            console.log("data ",data.url)
            if (data.url) {
                this.url = data.url;
            }
            if (data.url) {
                this.url = data.url;
            }
            if (data.url) {
                this.url = data.url;
            }
        }
    }
}
