import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
    selector: 'gk-repeat-until',
    templateUrl: './repeat-until.component.html',
    styleUrls: ['./repeat-until.component.scss'],
})
export class RepeatUntilComponent {

    @Input() parent: FormGroup;

    get until(): AbstractControl {
        return this.parent.get('until');
    }

    get count(): AbstractControl {
        return this.parent.get('count');
    }

    get date(): AbstractControl {
        return this.parent.get('date');
    }

    // change from UI needs to pass back to parent
    onDateInput($event: any): void {
        let m: moment.Moment = moment($event.value);
        this.setDate(m);
    }

    // change from UI needs to pass back to parent
    onDateChange($event: any): void {
        let m: moment.Moment = moment($event.value);
        this.setDate(m);
    }

    setDate(m: moment.Moment): void {
        let formattedString: string = m.format('YYYY-MM-DD');
        let tmp: any = { date: formattedString };
        this.parent.patchValue(tmp);
    }
}
