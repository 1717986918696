import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatMenuTrigger } from '@angular/material';

import { NGXLogger } from 'ngx-logger';

import { AuthService } from '../auth/auth.service';
import { IAccount } from '../models/account.model';
import { ProfileService, ProfileSingleton } from '../auth/profile.service';
import { ThemingService } from '../services';

export class AccountTreeNode {
    data: IAccount;
    children: AccountTreeNode[];
}

class MenuNode {
    id: string;
    label: string;
    depth: number;
    hasChildren: boolean;

}

@Component({
    selector: 'gk-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

    @ViewChild(MatMenuTrigger)
    accountMenu: MatMenuTrigger;

    @Input()
    allowAccountChange: boolean = true;

    @Output()
    accountChanged: EventEmitter<any> = new EventEmitter<any>();

    userName: string = undefined;
    effectiveAccountID: string = undefined;
    effectiveAccountName: string = undefined;
    availableAccounts: MenuNode[] = [];
    accountTree: AccountTreeNode[];

    theme1: string = 'classic-theme';
    theme2: string = 'blue-orange-dark';
    theme: string = this.theme1;

    constructor(
        private _router: Router,
        private auth: AuthService,
        private profile: ProfileService,
        private logger: NGXLogger,
        private themingService: ThemingService,
    ) { }

    ngOnInit(): void {
        this.profile.getProfile().subscribe(
            (profile: ProfileSingleton) => {
                this.userName = profile.username;
                // this.availableAccounts = profile.availableAccounts.map((item: IAccount) => { return { id: item.id, label: item.name }; });

                let nodes: AccountTreeNode[] = profile.availableAccounts.map((item: IAccount) => { return { data: item, children: [] }; });
                let roots: AccountTreeNode[] = this.populateChildren(nodes);
                this.accountTree = roots;
                let preorder: AccountTreeNode[] = this.preorderTraversal(roots);
                this.availableAccounts = preorder.map((item: AccountTreeNode) => {
                    return {
                        id: item.data.id,
                        label: item.data.name,
                        depth: item.data.hierarchyLevel,
                        hasChildren: item.children.length > 0,
                    };
                });

                this.displayEffectiveAccount(profile.effectiveAccountID);
            },
            (error: any) => {
                this.logger.error('error: user-menu:', error);
                this.logout();
            });
    }

    populateChildren(data: AccountTreeNode[]): AccountTreeNode[] {
        let roots: AccountTreeNode[] = [];
        // create a map of account_id -> AccountTreeNode
        let m: Map<string, AccountTreeNode> = new Map<string, AccountTreeNode>();
        data.forEach((element: AccountTreeNode, index: number) => {
            m[element.data.id] = element;
        });
        // add children to their parent's child lists.
        // if a node has no parent it is a root.  If a parent doesn't appear
        // it is a root of that user's access.
        data.forEach((element: AccountTreeNode, index: number) => {
            let parentId: string = element.data.parentId || element.data.parent_id;
            if ((!!parentId) && (!!m[parentId])) {
                m[parentId].children.push(element);
            } else {
                roots.push(element);
            }
        });
        return roots;
    }

    preorderTraversal(roots: AccountTreeNode[]): AccountTreeNode[] {

        let result: AccountTreeNode[] = [];
        roots.forEach((item: AccountTreeNode) => {
            result.push(item);
            item.children.sort((a: AccountTreeNode, b: AccountTreeNode) => a.data.name.localeCompare(b.data.name));
            result = result.concat(this.preorderTraversal(item.children));
        });
        return result;
    }

    logout(): void {
        this.auth.logout();
        this._router.navigate(['/login']);
    }

    onUserSettingsClicked(): void {
        //
    }

    onLogoutClicked(): void {
        this.logout();
    }

    onChoseAccount(id: string): void {
        this.displayEffectiveAccount(id);
        this.notififyChangeInEffectiveAccount(id);
    }

    toggleTheme(): void {
        this.theme = (this.theme === this.theme1) ? this.theme2 : this.theme1;
        this.themingService.changeTheme(this.theme);
    }

    private displayEffectiveAccount(id: string): void {
        this.effectiveAccountID = id;
        this.effectiveAccountName = this.nameForAccount(id);
    }

    private notififyChangeInEffectiveAccount(id: string): void {
        ProfileService.profileSingleton().effectiveAccountID = id;
        this.accountChanged.emit({ name: this.effectiveAccountName, id: this.effectiveAccountID });
        this.accountMenu.closeMenu();
    }

    private nameForAccount(accountID: string): string {
        const account: any = this.availableAccounts.find((acct: any) => acct.id === accountID);
        return (!!account) ? account.label : undefined;
    }
}
