import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { ThemingService } from './services';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
    selector: 'gk-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    theme: string = 'classic-theme';

    constructor(
        private _iconRegistry: MatIconRegistry,
        private _domSanitizer: DomSanitizer,
        private _themingService: ThemingService,
        private _overlay: OverlayContainer,
    ) {
        this._iconRegistry.addSvgIconInNamespace('assets', '3x4-portrait',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/3x4-portrait.svg'));
         this._iconRegistry.addSvgIconInNamespace('assets', 'pj-logo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pj-logo.svg'));    
        this._iconRegistry.addSvgIconInNamespace('assets', '9x16-portrait',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/9x16-portrait.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', '9x21-portrait',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/9x21-portrait.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', '4x3-landscape',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/4x3-landscape.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', '16x9-landscape',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/16x9-landscape.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', '21x9-landscape',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/21x9-landscape.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'addulate-1color',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/AddulateLogo-Long-1color.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'addulate',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/AddulateLogo-Long-3color.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'day',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_today_black_24px.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'week',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_week_black_24px.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'month',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_month_black_24px.svg'));

        this._iconRegistry.addSvgIconInNamespace('assets', 'teradata',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'github',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/github.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'covalent',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/covalent.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'covalent-mark',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/covalent-mark.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'teradata-ux',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teradata-ux.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'appcenter',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/appcenter.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'listener',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/listener.svg'));
        this._iconRegistry.addSvgIconInNamespace('assets', 'querygrid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/querygrid.svg'));
        this._themingService.themeChanged.subscribe(
            (newTheme: string) => {
                const oldTheme: string = this.theme;
                this.theme = newTheme;
                this._overlay.getContainerElement().classList.remove(oldTheme);
                this._overlay.getContainerElement().classList.add(newTheme);
            },
        );
    }

    ngOnInit(): void {
        this._overlay.getContainerElement().classList.add(this.theme);
    }
}
