import { Observable } from 'rxjs';
import { EventDialogComponent } from './event-dialog.component';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable()
export class EventDialogService {

    constructor(private dialog: MatDialog) { }
    /*
        public confirm(title: string, message: string): Observable<EventSchedule> {

            let dialogRef: MatDialogRef<EventDialogComponent>;

            dialogRef = this.dialog.open(EventDialogComponent);

            //dialogRef.componentInstance.title = title;
            //dialogRef.componentInstance.message = message;
            return dialogRef.afterClosed();
        }
        */
}
