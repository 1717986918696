import { Component, OnInit, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TdLoadingService } from '@covalent/core/loading/covalent-core-loading';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { ProfileService } from '../../auth/profile.service';
import { ScreenService } from '../../services';


enum WorkflowState {
    init,
    setData,
    polling,
    pairingError,
    pairingCompleteSuccess,
    pairingCompleteFail,
    pollingError,
}
let LOADING: string = 'LOADING';

@Component({
    selector: 'gk-pairing-dialog',
    templateUrl: './generateUrl-dialog.component.html'
    // ,
    // styleUrls: ['./generateUrl-dialog.component.scss'],
})
export class GenerateUrlDialogComponent implements OnInit, OnDestroy {
    key:string;
    newUrl:string=""
    accountId : string
    responseKey:string
    show:boolean = false
    wrongKey : boolean = false
    showForm : boolean = false
    constructor(
        private http: HttpClient,
        private screenService: ScreenService,
        public dialogRef: MatDialogRef<GenerateUrlDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
    ngOnInit(): void {
        this.wrongKey=false
        this.show=false

        console.log("data",this.data)
        this.accountId = ProfileService.profileSingleton().effectiveAccountID;
        this.getKey().subscribe(res=>{
            console.log("get response",res)
            this.responseKey=res['urlkey']
            this.show=true
        },(err=>{
           this.showForm=true
        }))

    }

    ngOnDestroy(): void {
       
    }

    saveUrlKey(){
        this.newUrl= "http://localhost:4200/#/"+"fakeUrl"+"/"+this.key;
    }

    copyUrl(){
        window.navigator['clipboard'].writeText(this.newUrl);
        // let n=navigator.clipboard.writeText(this.newUrl);
    }

    saveUrlInDB(){
        this.create().subscribe(res => {
            console.log('data response', res);
            alert("key saved")
            this.dialogRef.close();
          });    }

    create() {
        console.log("in createeee")
        const obj ={
            url : this.data.url,
            urlkey : this.key,
            screenId:this.data.id

        }
        // let url: string = 'http://localhost:8080/main/save'
        return this.screenService.createScreenUrl(obj)
    }

    getKey(){
        let id=this.data.id
        return this.screenService.getScreenUrl(id)
    }

    onCancelButtonPressed(): void {
        this.dialogRef.close();
    }

    keyPressAlphaNumeric(event) {

        var inp = String.fromCharCode(event.keyCode);
    
        if (/[a-zA-Z0-9]/.test(inp)) {
            this.wrongKey = false;

          return true;
        } else {
            this.wrongKey = true;
            console.log("regex text")
          event.preventDefault();
          return false;
        }
      }
}

