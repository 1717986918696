import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IAccount } from '../models/account.model';
import { IUser } from '../models/user.model';
import { AuthService } from '../auth/auth.service';
import { ProfileService } from '../auth/profile.service';
import { IDataArray, IRemoteReference } from '../models/data-array.model';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

    get headers(): HttpHeaders {

        let headers: HttpHeaders = new HttpHeaders();
        headers = this.auth.addAuthHttpHeaders(headers);
        headers = this.profile.addProfileHttpHeaders(headers);
        return headers;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private profile: ProfileService,
    ) { }

    getAll(): Observable<IDataArray<IUser>> {
        let url: string = environment.apiEndpoint + '/users';
        return this.http.get<IDataArray<IUser>>(url, { headers: this.headers });
    }

    getById(id: string): Observable<IUser> {
        let url: string = environment.apiEndpoint + '/users/' + id;
        return this.http.get<IUser>(url, { headers: this.headers });
    }

    create(user: IUser): Observable<HttpResponse<IRemoteReference<IUser>>> {
        let url: string = environment.apiEndpoint + '/users';
        return this.http.post<IRemoteReference<IUser>>(url, user, { headers: this.headers, observe: 'response' });
    }

    update(user: IUser): Observable<void> {
        let url: string = environment.apiEndpoint + '/users/' + user.id;
        return this.http.put<void>(url, user, { headers: this.headers });
    }

    delete(id: string): Observable<void> {
        let url: string = environment.apiEndpoint + '/users/' + id;
        return this.http.delete<void>(url, { headers: this.headers });
    }

    // we intentionally don't store the password in the user model,
    // so there's a not-very-CRUD-like api specially for passwords
    changePassword(id: string, password: string): Observable<void> {
        const url: string = environment.apiEndpoint + '/users/' + id;
        const body: any = { password: password };
        return this.http.put<void>(url, body, { headers: this.headers });
    }
}
